import { useState, Fragment, useEffect } from "react";
import { Tag, ButtonWithBg, Paragraph, Textarea, InputRadio, SelectField } from "../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { Modal } from "../../../components/Modals";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft} from 'react-icons/bs'
import { useSelector, useDispatch } from "react-redux";
import usePagination from "../../../utils/usePagination";
import {
    projectActions,
    surveyDatasRequestedActions,
    surveyDatasStatusActions,
  } from "../../../redux/actions";
import { useLocation } from "react-router-dom";
import { useToaster } from "../../../contexts/toaster";
import { Link } from "react-router-dom";
import { AiOutlineCopy } from 'react-icons/ai'
import LocationMappModal from "../LocationData/LocationDataModal";
import http from "../../../http-common";
import TableSkeleton from "../../../components/Skeletons/TableSkeleton";
import { formatNameSurvey } from "../../../utils/formatNameSurvey";
import UpdateSurveyForm from "../../../layout/forms/updateSurveyForm";
import { getSurveyId, retirerId } from "../../../utils/retireId";
import { removeEmpty } from "../../../utils/table-manager";
import { formatExternalProjectName } from "../../../utils/formatExternalProjectName";
import { FiFilter, FiSearch, FiSettings } from "react-icons/fi";
import UpdateSurveyStatus from "../../../layout/forms/updateSurveyStatus";
import Filter from "../../../layout/filter/Filter";
import { formatDateString } from "../../../utils/formatDate";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Table({
    isLoaded, item, 
    surveyDatas, selectedProject, t, 
    getSurveyData, nextPage, prevPage, pagination, 
    currentPage, elementToList, setElementToList, 
    setCurrentPage, dataPage, setAgentInfo, toggleOpenModal,
    filterValues, setFilterValues, accessControl, externalProject, externalProjectData, setElToSearch,
}){

    const dataView = [
        { name: 'Table View', id : 'table' },
        { name: 'Map View', id : 'map' },
    ]
    const toaster = useToaster()
    const dispatch = useDispatch()
    const location = useLocation();
    const [selectedView, setSelectedView] = useState(dataView[0])
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const surveyDatasStatus = useSelector((state) => state.surveyDatasStatus);
    const [suveryDatas, setSurveyDatas] = useState()
    const [getSd, setGetSd] = useState(null)
    const projects = useSelector((state) => state?.projects);
    const [projectItem, setProjectItem] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [dataMapps, setDataMapps] = useState({})
    const [assignNewAgent, setAssignNewAgent] = useState(false)
    const [newAgent, setNewAgent] = useState(null)

    console.log("itemitem", item)


    // const dataKeys = Object.keys(
    //     item?.surveyDatas !== undefined && item?.surveyDatas.length > 0
    //       ? item?.surveyDatas[0].data
    //       : []
    //   );

      const [surveyDataRequest, setSurveyDataRequest] = useState({
        surveyDatasId: "",
        description: "",
        statusId: "",
      });
      const [submitted, setSubmitted] = useState(false);
    
    // dataKeys.pop("datasMap");


      const displayStatusName = (id) => {
        let name = "";
        let status = surveyDatasStatus.find((s) => s.id === id);
    
        if (status !== undefined) name = status.name;
        return name;
      };

    const toggleModal = ()=>{
        setIsOpen(!isOpen)
        setAssignNewAgent(false)
        setNewAgent(null)
    }

    const handleCopy = (alpha, type) =>{
        let tab
        if(type == "arr"){
            tab = [...alpha?.map((data) =>(
                { lat: parseFloat(data?.latitude), lng: parseFloat(data?.longitude)}
            ))]
        }else{
            tab = alpha
        }
        const text = JSON.stringify(tab);
        navigator.clipboard.writeText(text)
        .then(() => toaster.show({
            title: 'Copied !',
            message: `Data successfully Copied`,
            greedy: true,
            type: 'info'
          }))
        .catch(() => toaster.show({
            title: 'Oups Error !',
            message: `Something went wrong please try again `,
            greedy: true,
            type: 'info'
          }));
    }

    function getLenght (alpha, beta, gamma){
        let a = alpha ? alpha : 0
        let b = beta ? beta : 0
        let c = gamma ? gamma : 0
        let d = item?.activeLocationExecution ? 1 : 0

        console.log("sum", a, b, c)

        return a + b + c + d
    }

    let [length, setLenght] = useState(7)
    useEffect(() => {
        item?.form?.useMapping ? setLenght(getLenght(item?.form?.fields.length, item?.form?.mapps?.length, 5)) : setLenght(getLenght(item?.form?.fields.length, item?.form?.mapps?.length, 4))
    }, [item]);


    
    // ------------------------------------------------------------ GESTION UPDATE SURVEY DATA

    const [initialValues, setInitialValues] = useState(null)
    const [isUpdatingSurvey, setIsUpdatingSurvey] = useState(false)

    useEffect(() => {
        if(getSd) {
            let obj = Object.assign({}, ...Object.entries(getSd?.survey).map(([key, value], k) => {
                if(item?.configuration?.externalProject?.fieldName && (String(item?.configuration?.externalProject?.fieldName)?.toLocaleLowerCase() === key)) {
                    return {
                        [formatNameSurvey(item?.configuration?.externalProject?.fieldName)]: getSurveyId(value?.value)
                    }
                } else {
                    return {
                        [key]: getSurveyId(value?.value)
                    }
                }   
            }))
            setInitialValues(obj)
        }
    }, [getSd])

    const updateSurveyData = async () => {
        setIsUpdatingSurvey(true)
        delete initialValues?.id
        
        let dataToSend = {
            surveyDatas: removeEmpty(initialValues),
            statusId: getSd?.status?.id,
            datasMap: {
                id: getSd?.datasMap?.id ? getSd?.datasMap?.id : null, 
                mappings: getSd?.datasMap?.mapps ? getSd?.datasMap?.mapps : []
            },
            userLocation: getSd?.userLocation
        }

        let foundIndex = surveyDatas.findIndex(x => x?.id === getSd?.id)

        await http.put(`/survey-datas/${getSd?.id}`, dataToSend)
        .then((response) => {
            setIsUpdatingSurvey(false)
            console.log('response update', response.data.data.surveyDatas)
            surveyDatas[foundIndex].survey = response.data.data.surveyDatas
            toaster.show({
                title: 'Success !',
                message: `Your survey has modified successfully`,
                greedy: true,
                type: 'success'
            });
            toggleModal()
            // window.location.reload()
        })
        .catch((err) => {
            toaster.show({
                title: 'Oups !',
                message: `Something was wrong`,
                greedy: true,
                type: 'error'
            });
            setIsUpdatingSurvey(false)
        })
    }

    // ------------------------------------------------------------ GET DATA SOURCE

    const formatData = (data) => {
        return data.map((item) => {
            // Get values from object
            const values = Object.values(item?.dataSource);
            // Join values with ' - '
            const content = values.join(' - ');
            // Return formatted object
            return { content, id: item?.id };
        });
    };

    const [datasForSource, setDataForSource] = useState(null)

    let dataSourcesToLoad = []
    useEffect(() => {
        if(item){
            item?.form?.fields?.forEach(element => {
                if(element?.type === 'select-data-source') {
                    console.log('element', element)
                    dataSourcesToLoad.push({name: element?.name, sourceId: element?.values?.[0]?.id})
                }
            });
        }
    }, [item])

    // Fonction asynchrone pour effectuer la requête HTTP
    const fetchData = async (id) => {
        try {
            const response = await http.get(`/datas-sources-by-form-data-source-id/${id}`);

            let createArray = response?.data?.data?.map((el) => (
                {
                    dataSource: el.dataSource,
                    id: el.id
                }
            ))
            let finalArray = formatData(createArray)
            // let finalArray = []
            return finalArray;
        } catch (error) {
            console.error(`Erreur lors de la requête pour ${id}:`, error);
            return null;
        }
    };

    // Fonction principale pour traiter chaque élément dans dataSourcesToLoad
    const processDataSources = async () => {
        const promises = dataSourcesToLoad.map(dataSource => fetchData(dataSource.sourceId));

        try {
            // Attendre que toutes les promesses soient résolues
            const results = await Promise.all(promises);

            // Construire l'objet résultant
            const ggg = dataSourcesToLoad.reduce((acc, dataSource, index) => {
                const { name } = dataSource;
                acc[name] = results[index];
                return acc;
            }, {});

            setDataForSource(ggg)
        } catch (error) {
            console.error('Erreur lors de l\'exécution de Promise.all:', error);
        }
    };

    useEffect(() => {
        if(dataSourcesToLoad?.length > 0){
            processDataSources();
        }
    }, [dataSourcesToLoad])


    // --------------
    const [externalProjectInfo, setExternalProjectInfo] = useState(null)
    const [isLoadExternalProjectInfo, setIsLoadExternalProjectInfo] = useState(false)
    const getSurveyById = async (id, surveyDataEL) => {
        // console.log('aaaa', id, surveyDataEL)
        setIsLoadExternalProjectInfo(true)
        await http.get(`/survey-datas/${id}`)
        .then((response) => {
            console.log('response ddd', response)
            if(surveyDataEL){
                let formatWithSurvey = {
                    ...surveyDataEL,
                    survey: {
                        ...surveyDataEL?.survey,
                        externalProjectData: response?.data?.data?.surveyDatas
                    }
                }
                setGetSd(formatWithSurvey)
                toggleModal()
            } else{
                setExternalProjectInfo({
                    ...response?.data?.data?.surveyDatas, 
                    number: response?.data?.data?.number
                })
                setIsLoadExternalProjectInfo(false)
            }
        })
        .catch((err) => {
            setIsLoadExternalProjectInfo(false)
            toaster.show({
                title: 'Oups !',
                message: `Something was wrong`,
                greedy: true,
                type: 'error'
            });
        })
    }


    console.log('externalProjectInfo', externalProjectInfo)


    return(
        <>
        <div className='flex items-center justify-between mt-[30px]'>
            <div className="flex justify-between md:justify-start items-center gap-3">
                <div className="rounded-[6px] w-[400px] h-[40px] px-2 overflow-hidden bg-white flex items-center gap-2 border relative z-40">
                    <FiSearch className='text-[20px]' />
                    <input
                        type='search'
                        placeholder="Rechercher..."
                        className="block h-full flex-1 focus:outline-none text-[14px] text-black placeholder:text-black placeholder:text-opacity-50"
                        onChange={(e) => {
                            setElToSearch(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                getSurveyData();
                            }
                        }}
                    />
                    <div 
                        className="cursor-pointer hover:text-primary-blue-500"
                        onClick={() => {
                            setModalMode("filter")
                            toggleModal()
                        }}
                    >
                        <FiFilter />
                    </div>
                </div>
                <div className="flex shrink-0 justify-between md:justify-start items-center gap-3">
                    <span className="text-[14px] block text-[#676D7C] font-poppins font-regular">
                        {t("project:showitems")}
                    </span>
                    <Listbox 
                        value={elementToList} 
                        onChange={(e) => {
                            setElementToList(e)
                            setCurrentPage(1)
                        }}>
                        <div className="relative">
                            <Listbox.Button className="inline-flex h-[40px] min-w-[80px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[4px] bg-[#fff] border border-[#EFEFEF] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <span className="block text-[12px] text-[#676D7C] mr-4 font-title font-medium">{elementToList.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute left-0 w-full mt-2 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {dataPage?.map((item, itemIdx) => (
                                        <Listbox.Option
                                            key={itemIdx}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[12px] font-poppins ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {item.name}
                                                    </span>
                                                    
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div>
                {/* <div className="flex shrink-0 justify-between md:justify-start items-center gap-3">
                    <span className="text-[14px] block text-[#676D7C] font-poppins font-regular">Status</span>
                    <Listbox 
                        value={elementToList} 
                        onChange={(e) => {
                            setFilterByStatus(e)
                            setCurrentPage(1)
                        }}>
                        <div className="relative">
                            <Listbox.Button className="inline-flex h-[40px] min-w-[150px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[4px] bg-[#fff] border border-[#EFEFEF] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <span className="block text-[12px] text-[#676D7C] mr-4 font-title font-medium">
                                    {filterByStatus?.name ? t(`project:${filterByStatus?.name?.replaceAll(" ", "")}`) : t("project:All")}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </Listbox.Button>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute left-0 w-full mt-2 z-50 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <>
                                        <Listbox.Option
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                }`
                                            }
                                            value={null}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[12px] font-poppins ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {t("project:All")}
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    {surveyDatasStatus?.map((item, itemIdx) => (
                                        <Listbox.Option
                                            key={itemIdx}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                    active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                }`
                                            }
                                            value={item}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span
                                                        className={`block text-[12px] font-poppins ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {
                                                            t(`project:${item?.name?.replaceAll(" ", "")}`)
                                                        }
                                                    </span>
                                                    
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </>
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                </div> */}
                <div className="inline-flex h-[40px] min-w-[80px] justify-between items-center px-2 py-2 text-sm text-gray-500 rounded-[4px] bg-[#fff] border border-[#EFEFEF]">
                    <span className="block w-full text-[12px] text-[#676D7C] font-title font-medium text-center">
                        {/* {isLoaded ? (
                            <div className="animate-pulse text-left border-b border-dashed border-[#EFEFEF]">
                                <div className={`h-4 w-full bg-black/10 rounded-[2px]`}></div>
                            </div>
                            ) : ( */}
                                <>
                                    <strong className="text-black">{pagination?.totalCount}</strong> saisie(e)
                                </>
                            {/* )
                        } */}
                    </span>
                </div>
            </div>
            {
                pagination.total > 1 &&
                <div className="flex items-center justify-between gap-4">
                    <span className="text-[14px] text-black text-opacity-50">
                        {`Page ${currentPage} sur ${pagination.total}`}
                    </span>
                    <div className="flex items-center justify-end gap-3">
                        <div 
                            onClick={()=>prevPage("surveyData")} 
                            className={`bg-primary-blue-500 bg-opacity-10 h-[30px] w-[30px] rounded-[6px] flex items-center justify-center cursor-pointer ${currentPage < 2 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                        >
                            <BsChevronLeft className="text-[16px] text-primary-blue-500" />
                        </div>
                        <div 
                            onClick={()=>nextPage("surveyData")}  
                            className={`bg-primary-blue-500 bg-opacity-10 h-[30px] w-[30px] rounded-[6px] flex items-center justify-center cursor-pointer ${currentPage == pagination.total ? 'cursor-not-allowed' : 'cursor-pointer'}`}    
                        >
                            <BsChevronRight className="text-[16px] text-primary-blue-500" />
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className='relative bg-white rounded-[6px] pb-[300px] border border-[#EFEFEF] overflow-auto tableLand:overflow-visible scrollbar mb-6 mt-[20px]'>
            <div className="w-full">
                <table className="w-full text-[#484964]">
                    <thead className=" border-b border-[#EFEFEF] bg-tertiary-gray-500">
                        <tr className=" text-left text-opacity-90 h-[40px]">
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                Nº
                            </th>
                            {item?.form?.fields?.map((data, index) => {
                                if (data !== "status") {
                                return (
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {data?.label}
                                    </th>
                                );
                                }
                            })}
                            {item?.form?.useMapping && item?.form?.mapps?.map((data, index) => {
                                if (data !== "status") {
                                return (
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {data?.label}
                                    </th>
                                );
                                }
                            })}
                            {
                                item?.configuration?.useExternalProject && (
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-primary-blue-500 text-[14px]  '>
                                        {item?.configuration?.externalProject?.fieldName}
                                    </th>
                                )
                            }
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {t("project:creationdate")}
                            </th>
                            { item?.activeLocationExecution && 
                                <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                    {t("project:userlocation")}
                                </th>
                            }
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Agent"}
                            </th>
                            {/*<th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Update story"}
                            </th>*/}
                            <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                {"Status"}
                            </th>
                            {
                                (accessControl?.maintainer || accessControl?.owner) && (
                                    <th className='whitespace-nowrap  px-4 font-[500]  text-[#000] text-opacity-50 text-[14px]  '>
                                        Action
                                    </th>
                                )
                            }
                            
                        </tr>
                    </thead>
                    <tbody className={`relative divide-y`}>
                        {
                            isLoaded ? <TableSkeleton length={10}  columns={length} /> 
                            :
                            <>
                                {
                                    surveyDatas !== undefined &&
                                    surveyDatas?.length > 0 &&
                                    surveyDatas?.map((surveyData, idx) => {
                                        return (
                                            <tr key={idx}  className='text-left border-b  border-[#EFEFEF]'>
                                                <td className="px-4 py-1  text-[14px] text-[#000] text-opacity-50  font-[500] ">
                                                    {surveyData?.number ? surveyData?.number : ""}
                                                </td>
                                                {
                                                    item?.form?.fields?.map((el, index) => {
                                                        if (el?.name !== "status") {
                                                        return (
                                                            <td key={index} className="px-4 py-3 capitalize  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                                <div className="min-w-[100px] max-w-[200px]">
                                                                    {surveyData?.survey ? retirerId(surveyData?.survey[el?.name]?.value) : ''}
                                                                </div>
                                                            </td>
                                                        );
                                                        }
                                                    })
                                                }
                                                {
                                                    item?.form?.useMapping && item?.form?.mapps?.map((el, index) => {
                                                        if (el?.name !== "status") {
                                                        return (
                                                            <td key={index} className="px-4 py-3 whitespace-nowrap  text-[14px] text-primary-blue-500 text-opacity-90  font-[500] align-middle ">
                                                                <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                                    <div 
                                                                        key={idx} 
                                                                        className='flex-1'
                                                                        onClick={() => {
                                                                            if(surveyData?.datasMap?.mapps?.length > 0 ){
                                                                                setModalMode("locationMapps")
                                                                                setDataMapps({ 
                                                                                    type : el?.type, 
                                                                                    name: el?.label ,  
                                                                                    coordinates: surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.coordinates, 
                                                                                    centerDataMapps: {
                                                                                        lat: surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.mappingCenter?.latitude, 
                                                                                        lng: surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.mappingCenter?.longitude
                                                                                    } ,
                                                                                    srv: surveyData
                                                                                })

                                                                                if(item?.configuration?.useExternalProject && !surveyData?.survey?.externalProjectData){
                                                                                    let aa = getSurveyId((surveyData?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value))
                                                                                    getSurveyById(aa, surveyData)
                                                                                    // setModalMode("locationMapps")
                                                                                } else {
                                                                                    setGetSd(surveyData)
                                                                                    // setModalMode("locationMapps")
                                                                                    toggleModal()
                                                                                }
                                                                            }
                                                                            
                                                                        }}
                                                                    >
                                                                        {
                                                                            surveyData?.datasMap?.mapps?.length > 0 ? "@h"+String(surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.coordinates?.[0]?.latitude)?.substring(0, 4) + "k" + String(surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.coordinates?.[0]?.longitude)?.substring(0, 6) : '--------'
                                                                            // surveyData?.data ? surveyData?.data?.datasMap?.mapps?.find(x => x?.name == el?.name)?.coordinates?.map(coord => ("h"+coord?.latitude)) : ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                        surveyData?.datasMap?.mapps?.length > 0 && (
                                                                            <div 
                                                                                className='h-[30px] w-[30px] rounded-[4px] cursor-pointer bg-primary-blue-500 bg-opacity-20 flex items-center justify-center' 
                                                                                onClick={()=> handleCopy(surveyData?.datasMap?.mapps?.find(x => x?.name === el?.name)?.coordinates, "arr")}
                                                                            >
                                                                                <AiOutlineCopy className='text-primary-blue-500 text-[16px]' />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    
                                                                </div>
                                                            </td>
                                                        );
                                                        }
                                                    })
                                                }
                                                {
                                                    item?.configuration?.useExternalProject && (
                                                        <td 
                                                            className="px-4 py-3 capitalize  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle "
                                                            onClick={() => {
                                                                if(retirerId(surveyData?.survey[formatNameSurvey(item?.configuration?.externalProject?.fieldName)]?.value)){
                                                                    setModalMode("view_data_survey")
                                                                    toggleModal()
                                                                    let aa = getSurveyId((surveyData?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value))
                                                                    getSurveyById(aa)
                                                                }
                                                            }}
                                                        >
                                                            <div 
                                                                className="min-w-[100px] max-w-[200px] cursor-pointer text-primary-blue-500 underline underline-offset-2"
                                                            >
                                                                <>
                                                                    {
                                                                        formatExternalProjectName(selectedProject, surveyData) ?? 
                                                                        <>
                                                                            {
                                                                                item?.configuration?.externalProject?.fieldName ? 
                                                                                retirerId(surveyData?.survey[formatNameSurvey(item?.configuration?.externalProject?.fieldName)]?.value) 
                                                                                : 
                                                                                '---------'
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                                <td className="px-4 py-3 lowercase  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle whitespace-nowrap">
                                                    { 
                                                        // new Date(surveyData?.createdAt?.split("T")[0]).toLocaleDateString().replaceAll("/", "-")
                                                        surveyData?.createdAt ? formatDateString(surveyData?.createdAt) : '--------'
                                                    }
                                                </td>
                                                { item?.activeLocationExecution && 
                                                    <td className="px-4 py-3  text-[14px] text-primary-blue-500 whitespace-nowrap  font-[500] align-middle ">
                                                        <div className='flex items-center gap-2 cursor-pointer w-full'>
                                                            <div 
                                                                key={idx} 
                                                                className='flex-1'
                                                                onClick={() => {
                                                                    setModalMode("locationMapps")
                                                                    setDataMapps({type : 'userLocation', name: "User Location" , coordinates: surveyData?.userLocation, centerDataMapps: null, srv: surveyData})
                                                                    toggleModal()
                                                                }}
                                                            >
                                                                {
                                                                    "@h"+surveyData?.userLocation?.latitude?.toString()?.substring(0, 4) +"k"+ surveyData?.userLocation?.longitude?.toString()?.substring(0, 6) 
                                                                }
                                                            </div>
                                                            <div 
                                                                className='h-[30px] w-[30px] rounded-[4px] cursor-pointer bg-primary-blue-500 bg-opacity-20 flex items-center justify-center' 
                                                                onClick={()=> handleCopy(surveyData?.userLocation)}
                                                            >
                                                                <AiOutlineCopy className='text-primary-blue-500 text-[16px]' />
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                <td className="px-4 py-3 capitalize  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                    <div
                                                        onClick={() => {
                                                            setAgentInfo(surveyData?.user)
                                                            toggleOpenModal()
                                                        }}
                                                        className="cursor-pointer text-primary-blue-500 underline underline-offset-2"
                                                    >
                                                        {surveyData?.user?.firstName + " " + surveyData?.user?.lastName}
                                                    </div>
                                                </td>
                                                {/*<td className="px-4 py-3  text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                    <span  
                                                        className='text-primary-blue-500 cursor-pointer'
                                                        onClick={() => {
                                                            setModalMode('update_history')
                                                            toggleModal()
                                                        }}
                                                    >
                                                        {"View story"}
                                                    </span>
                                                </td>*/}
                                                <td className="px-4 py-3 whitespace-nowrap text-[14px] text-[#000] text-opacity-50  font-[500] align-middle ">
                                                    <Tag 
                                                        isHfull
                                                        title={ t(`project:${displayStatusName(surveyData?.status?.id)?.replaceAll(" ", "")}`)}
                                                        color={
                                                            displayStatusName(surveyData?.status?.id) == 'Validated' ? 'text-secondary-green-500' : 
                                                            displayStatusName(surveyData?.status?.id) == 'Received' ? 'text-black' : 
                                                            displayStatusName(surveyData?.status?.id) == 'Request Correction' ? 'text-[#FF7B54]' : 
                                                            displayStatusName(surveyData?.status?.id) == "To update" ? 'text-[#0081C9]' : 
                                                            displayStatusName(surveyData?.status?.id).includes("Archive") ? 'text-[#0A2647]' : 
                                                            displayStatusName(surveyData?.status?.id).includes("Delete") ? 'text-red-500' : ''
                                                        }
                                                        bg={
                                                            displayStatusName(surveyData?.status?.id) == 'Validated' ? 'bg-secondary-green-500 bg-opacity-10' : 
                                                            displayStatusName(surveyData?.status?.id) == 'Received' ? 'bg-gray-500 bg-opacity-10' : 
                                                            displayStatusName(surveyData?.status?.id) == 'Request Correction' ? 'bg-[#FF7B54] bg-opacity-10' : 
                                                            displayStatusName(surveyData?.status?.id) == "To update" ? 'bg-[#0081C9] bg-opacity-10' : 
                                                            displayStatusName(surveyData?.status?.id).includes("Archive") ? 'bg-[#0A2647] bg-opacity-10' : 
                                                            displayStatusName(surveyData?.status?.id).includes("Delete") ? 'bg-red-500 bg-opacity-10' : null
                                                        }
                                                    />
                                                </td>
                                                {
                                                    (accessControl?.maintainer || accessControl?.owner) && (
                                                        <td className="px-4 py-2 whitespace-nowrap">
                                                            <div className="">
                                                                <Menu as="div" className="relative inline-block text-left">
                                                                    <Menu.Button className="text-sm ">
                                                                        <div className="border px-3 py-2 rounded-[4px] text-[12px] font-poppins font-[500] flex items-center gap-6">
                                                                            <span className="text-[#676D7C] ">Actions</span>
                                                                            <svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            </svg>
                                                                        </div>
                                                                    </Menu.Button>
                                                                    <Transition
                                                                        as={Fragment}
                                                                        enter="transition ease-out duration-100"
                                                                        enterFrom="transform opacity-0 scale-95"
                                                                        enterTo="transform opacity-100 scale-100"
                                                                        leave="transition ease-in duration-75"
                                                                        leaveFrom="transform opacity-100 scale-100"
                                                                        leaveTo="transform opacity-0 scale-95"
                                                                    >
                                                                        <Menu.Items className="absolute z-20 right-0 w-[140px]  mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                            <div className="px-1 py-1">
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                setModalMode('view_data')
                                                                                                if(item?.configuration?.useExternalProject && !surveyData?.survey?.externalProjectData){
                                                                                                    let aa = getSurveyId((surveyData?.survey[formatNameSurvey(selectedProject?.configuration?.externalProject?.fieldName)]?.value))
                                                                                                    getSurveyById(aa, surveyData)
                                                                                                } else {
                                                                                                    setGetSd(surveyData)
                                                                                                    toggleModal()
                                                                                                }
                                                                                            }}
                                                                                            className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                                } group flex rounded-md items-center w-full px-2 py-2 text-[12px]`}
                                                                                        >
                                                                                            {t("project:viewdetails")}
                                                                                        </button>
                                                                                    )}
                                                                                </Menu.Item>
                                                                            </div>
                                                                            <div className="px-1 py-1">
                                                                                <span className="text-[12px] text-secondary-green-500 px-2 py-2">Status:</span>
                                                                                {
                                                                                    surveyDatasStatus?.map((status, idx) =>(
                                                                                        <Menu.Item key={idx}>
                                                                                            {({ active }) => (
                                                                                                <button
                                                                                                    onClick={() => {
                                                                                                        // if((item?.configuration?.useExternalProject && status?.name !== 'To update') || !item?.configuration?.useExternalProject){
                                                                                                            setGetSd(surveyData)
                                                                                                            setSurveyDataRequest({
                                                                                                                ...surveyDataRequest,
                                                                                                                statusId: status?.id,
                                                                                                                surveyDatasId: surveyData?.id,
                                                                                                                description: '',
                                                                                                            });
                                                                                                            (status?.name === 'To update') ? setModalMode("order") : setModalMode('otherState')
                                                                                                            toggleModal()
                                                                                                        // }
                                                                                                    }}
                                                                                                    className={`${active ? 'bg-[#00c28818] text-myGreen' : 'text-black'
                                                                                                        } group flex rounded-md items-center w-full px-2 py-2 text-[12px]  "cursor-pointer
                                                                                                        `}
                                                                                                >
                                                                                                    {t(`project:${status?.name?.replaceAll(" ", "")}`)}
                                                                                                </button>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </Menu.Items>
                                                                    </Transition>
                                                                </Menu>
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    
    

        {/** Modal */}
        <Modal show={isOpen} toggleModal={() =>{toggleModal(); setExternalProjectInfo(null)}}>
            {
                modalMode !== 'filter' && (
                    <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                        <div className="flex-1 flex items-center justify-between">
                            <h4>
                                {
                                    modalMode === 'update_history' ? "Update history" :
                                    modalMode === 'view_data' ? t("project:viewdatainfo") : 
                                    modalMode === 'view_data_survey' ? t("project:viewdatainfo") : 
                                    modalMode === 'export_data' ? t("project:exportdata") : 
                                    modalMode === 'order' ? t("project:order") : 
                                    modalMode === 'otherState' ? t("project:warning") : 
                                    modalMode === 'locationMapps' ? 
                                    <>
                                        {   dataMapps?.type === 'userLocation' ? "User location" :
                                            `${dataMapps?.name} - ${formatExternalProjectName(selectedProject, getSd)}`
                                        }
                                    </>
                                    : ''
                                }
                            </h4>
                            {
                                modalMode === 'locationMapps' && (
                                    <div className="bg-gray-200 px-[12px] py-[8px] rounded-[8px]">
                                        <span>
                                            Id: {getSd?.number ?? '----'}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                (modalMode === 'view_data_survey' && externalProjectInfo) && (
                                    <div className="bg-gray-200 px-[12px] py-[8px] rounded-[8px]">
                                        <span>
                                            Id: {externalProjectInfo?.number ?? '----'}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                        <div 
                            onClick={()=>{toggleModal(); setExternalProjectInfo(null)}}
                            className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                            <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                        </div>
                    </div>
                )
            }
            
            {   modalMode=='view_data' &&
                <>
                    <div className="p-6 max-h-[400px] overflow-x-hidden">
                        <UpdateSurveyForm 
                            project={item}
                            getSd={getSd}
                            initialValues={initialValues}
                            setInitialValues={setInitialValues}
                            datasForSource={datasForSource}
                            externalProjectData={externalProjectData}
                        />
                        <div className="pt-8">
                            <span className='block mb-1 text-[14px] text-black text-opacity-50'>Agent Investigator</span>
                            <div className="flex items-center gap-2 py-2">
                                <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                    <img
                                        src="/images/avatar.png"
                                        alt="profil"
                                        className="h-full w-full object-cover"
                                    />
                                </div>
                                <div>
                                    <h4 className="text-[14px] text-black  leading-[15px]">{getSd?.user?.firstName +" "+ getSd?.user?.lastName}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={isUpdatingSurvey ? t("common:inprogress") : t("project:savechange")}
                            type='submit'
                            bg={'primary'}
                            isSmall={true}
                            disabled={isUpdatingSurvey}
                            onClick={() => {
                                // if(!item?.configuration?.useExternalProject)
                                updateSurveyData()
                            }}
                        />
                    </div>
                </>
            }
            {   modalMode==='view_data_survey' &&
                <>
                    <div className="p-6 max-h-[400px] overflow-x-hidden">
                        <div className='grid grid-cols-2 gap-4'>
                            
                            {externalProject?.form?.fields?.map((el, index) => {
                                if (el?.name !== "status") {
                                    return (
                                        <div key={index} className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                            <span className='block mb-1 text-[14px] text-black text-opacity-50 capitalize'>{el?.label}</span>
                                            <span className='block text-[14px] text-black capitalize'>
                                                
                                                {   isLoadExternalProjectInfo ? 
                                                    <span class="animate-pulse block">
                                                        <span class="h-2 bg-slate-200 rounded-sm block"></span>
                                                    </span> 
                                                    :
                                                    externalProjectInfo ? retirerId(externalProjectInfo[el?.name]?.value) : ''
                                                }
                                            </span>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={t("common:close")}
                            type='button'
                            bg={'primary'}
                            isSmall={true}
                            onClick={() => {toggleModal(); setExternalProjectInfo(null)}}
                        />
                    </div>
                </>
            }
            {
                modalMode==='update_history' &&
                <>
                    <div className="p-6 max-h-[400px] overflow-x-hidden">
                        <Disclosure className=' mb-3'>
                            {({ open }) => (
                                <div className={`rounded-lg overflow-hidden } `}>
                                    <Disclosure.Button className={`transition ease-in duration-400 flex items-center justify-between py-2 cursor-pointer w-full border-b border-dashed border-gray-200`}>
                                        <div className={`flex items-center justify-between gap-3 w-full`}>
                                            <span className={`
                                                text-[14px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                            }`}>
                                                {"Update of 12-12-2020"}
                                            </span>
                                            <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className=' py-4 bg-opacity-70'>
                                        <div>
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2 py-2 pt-8">
                                                <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                                    <img
                                                        src="/images/avatar.png"
                                                        alt="profil"
                                                        className="h-full w-full object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    <h4 className="text-[14px] text-black  leading-[15px]">Bah Ismaila</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </div>
                            )}
                        </Disclosure>
                        <Disclosure className=' mb-3'>
                            {({ open }) => (
                                <div className={`rounded-lg overflow-hidden } `}>
                                    <Disclosure.Button className={`transition ease-in duration-400 flex items-center justify-between py-2 cursor-pointer w-full border-b border-dashed border-gray-200`}>
                                        <div className={`flex items-center justify-between gap-3 w-full`}>
                                            <span className={`
                                                text-[14px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                            }`}>
                                                {"Update of 12-12-2020"}
                                            </span>
                                            <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className=' py-4 bg-opacity-70'>
                                        <div>
                                            <div className='grid grid-cols-2 gap-4'>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                </div>
                                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2 py-2 pt-8">
                                                <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                                    <img
                                                        src="/images/avatar.png"
                                                        alt="profil"
                                                        className="h-full w-full object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    <h4 className="text-[14px] text-black  leading-[15px]">Bah Ismaila</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </div>
                            )}
                        </Disclosure>
                    </div>
                </>
            }
            {
                modalMode==='export_data' &&
                <>
                    <div className="p-6 min-h-[450px] overflow-x-hidden">
                        <Tab.Group>
                            <Tab.List className="inline-flex gap-2 overflow-y-hidden">
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:table")}
                                </Tab>
                                <Tab
                                    className={({ selected }) =>
                                        classNames(
                                        "w-full rounded-[6px] whitespace-nowrap flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize",
                                        "focus:outline-none",
                                        selected
                                            ? "bg-primary-blue-500 text-white"
                                            : "bg-primary-blue-500 bg-opacity-10 text-primary-blue-500"
                                        )
                                    }
                                >
                                    {t("project:geolocation")}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className="mt-2 pt-[30px]">
                                <Tab.Panel className={classNames("focus:outline-none")}>
                                    <form>
                                        <div className="mb-[30px]">
                                            <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                            <select className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1">
                                                <option value='excel'>Excel</option>
                                                <option value='csv'>Csv</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label className="text-[14px] text-black text-opacity-50">{t("project:linestoexport")}</label>
                                            <div className="flex items-center gap-4">
                                                <input 
                                                    type='number'
                                                    min='0'
                                                    className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                                />
                                                <span className="text-[14px] text-black text-opacity-50">{t("project:to")}</span>
                                                <input 
                                                    type='number'
                                                    min='0'
                                                    className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </Tab.Panel>
                                <Tab.Panel className={classNames("focus:outline-none")}>
                                    <form>
                                        <div className="mb-[30px]">
                                            <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                            <select className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1">
                                                <option value='excel'>Excel</option>
                                                <option value='csv'>Gpx Track</option>
                                            </select>
                                        </div>
                                        <div className="mb-[30px]">
                                            <label className="text-[14px] text-black text-opacity-50">{t("project:locationcolumn")}</label>
                                            <select className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1">
                                                <option value='excel'>Location</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label className="text-[14px] text-black text-opacity-50">{t("project:linestoexport")}</label>
                                            <div className="flex items-center gap-4">
                                                <input 
                                                    type='number'
                                                    min='0'
                                                    className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                                />
                                                <span className="text-[14px] text-black text-opacity-50">{t("project:to")}</span>
                                                <input 
                                                    type='number'
                                                    min='0'
                                                    className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                    <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                        <ButtonWithBg 
                            title={t('project:export')}
                            type='button'
                            bg={'primary'}
                            isSmall={true}
                            onClick={() => console.log('first')}
                        />
                    </div>
                </>
            }
            {
                (modalMode==='order' ||  modalMode==='otherState') &&
                <UpdateSurveyStatus 
                    surveyDataRequest={surveyDataRequest}
                    setSurveyDataRequest={setSurveyDataRequest}
                    getSurveyData={getSurveyData}
                    toggleModal={toggleModal}
                    getSd={getSd}
                    project={item}
                    modalMode={modalMode}
                />
            }
            {
                modalMode==='locationMapps' && 
                <div>
                    <LocationMappModal state={dataMapps} project={item} externalProject={externalProject} />
                </div>
            }
            {
                modalMode==='filter' &&  <Filter 
                    toggleModal={toggleModal} 
                    project={item}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    getSurveyData={getSurveyData}
                />
            }
        </Modal>
        </>
    )
}
