import { useState, Fragment, useEffect } from "react";
import { Tag, ButtonWithBg, Heading, Paragraph } from "../../../components/Kits";
import { Menu, Transition, Listbox, Disclosure, Dialog, Tab } from '@headlessui/react'
import { Modal, ModalUserInfo } from "../../../components/Modals";
import { AiOutlineInfoCircle , AiOutlineClose} from 'react-icons/ai'
import { BsChevronRight} from 'react-icons/bs'
import { BiCurrentLocation, BiRefresh} from 'react-icons/bi'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Table from "./Table";
import http from "../../../http-common";
import { useTranslation } from "react-i18next";
import { moduleMappingActions, projectActions } from "../../../redux/actions";
import ModuleMappingTable from "./ModuleMappingTable";
import { objectToQueryString } from "../../../utils/table-manager";
import convertToCSV from "../../../utils/csvDownload";
import convertToXLSX from "../../../utils/xlsxDownload";
import { handleClick } from "../../../utils/downloadGeolocation";
import { formatedDataForCsvAndXlsxFile, formatedDataForGeolocationFile, formatedHeadersXlsxAndCsv, formatedDataForGeolocationFileForModuleMapping } from "../../../utils/helpers";
import ModalExportData from "../../../components/Modals/ModalExportData";
import { controlAccess } from "../../../utils/controlAccess";
import { formatStringRenameExport } from "../../../utils/formatStringRenameExport";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const dataPage = [
    { name: '10', id : 10 },
    { name: '20', id : 20 },
    { name: '30', id : 30 },
    { name: '40', id : 40 },
    { name: '50', id : 50 },
    { name: '100', id : 100 },
    { name: '150', id : 150 }
]

export default function DataHub(){
    const dispatch = useDispatch();
    const { t } = useTranslation(["common", "project", "form"]);
    const projects = useSelector((state) => state?.projects);
    const user = useSelector((state) => state?.user);
    const surveyDatasStatus = useSelector((state) => state.surveyDatasStatus);
    const userStudyArea = useSelector((state) => state.userStudyArea)
    const [datasMap, setDatasMap] = useState([]);
    const dataView = [
        { name: t("project:tableview"), id : 'table' },
        { name: t("project:mapview"), id : 'map' },
    ]
    const [agentInfo, setAgentInfo] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const toggleOpenModal = () => setOpenModal(!openModal)
    const [Projects, setProj] = useState([])
    const [selectedView, setSelectedView] = useState(dataView[0])
    const [selectedProject, setSelectedProject] = useState(null)
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const [surveyData, setSurveyData] = useState()
    const [surveyDataWithOutLimit, setSurveyDataWithOutLimit] = useState([])
    const [viewModuleMapping, setViewModuleMapping] = useState(false)
    const navigate = useNavigate();
    const [projectsApplications, setProjectApplication] = useState([])
    const [loadingProject, setLoadingProject] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoadedDataMaps, setIsLoadedDataMaps] = useState(false)
    const [elementToList, setElementToList] = useState(dataPage[0])
    const [filterByStatus, setFilterByStatus] = useState(null)
    const [elementToListDataMaps, setElementToListDataMaps] = useState(dataPage[0])
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageDataMaps, setCurrentPageDataMaps] = useState(1);
    const [elToSearch, setElToSearch] = useState('')
    const [pagination, setPagination] = useState({
        pageSize: elementToList?.id,
        total : 0,
        totalCount: 0
    });
    const [paginationDataMaps, setPaginationDataMaps] = useState({
        pageSize: elementToListDataMaps?.id,
        total : 0,
        totalCount: 0
    });
    const [paginationExport, setPaginationExport] = useState({
        total : 0,
        totalCount: 0,
        pageSize: 500
    });
    const [externalProject, setExternalProject] = useState(null)
    const [filterValues, setFilterValues] = useState({
        agentIds: null,
        statusIds: null,
        numbers: '',
        createdAt: ''
    })

    const resetFilter = () => {
        setFilterValues({
            agentIds: null,
            statusIds: null,
            number: '',
            createdAt: ''
        })
    }

    useEffect(()=>{
        setSelectedProject(projectsApplications[0])
    }, [projectsApplications])

    {/** --------------- Start Params to export file  */}
    const [downloadInfo, setDownloadInfo] = useState({
        type: "table",
        format: "excel",
        page: 1,
        limit: 500,
        from: 1,
        to: surveyDataWithOutLimit?.length,
        concernedField: '',
        surveysStatus: [],
        fileName: []
    });

    useEffect(()=>{
        if(viewModuleMapping){
            setDownloadInfo({
                ...downloadInfo,
                to: datasMap?.length
            })
        } else{
            setDownloadInfo({
                ...downloadInfo,
                to: surveyDataWithOutLimit?.length
            })
        }
    }, [selectedProject, datasMap, viewModuleMapping, surveyDataWithOutLimit])

    {/** --------------- End Params to export file  */}


    const displayStatusName = (id) => {
        let name = "";
        let status = surveyDatasStatus.find((s) => s.id === id);
    
        if (status !== undefined) name = status.name;
        return name;
    };
    

    function nextPage(val) {
        // Vérifier dab si on peut faire suivant
        
        if(currentPage < pagination.total && val === 'surveyData'){
            console.log('next')
            setCurrentPage(currentPage + 1)
        } else{
            if(currentPageDataMaps < paginationDataMaps.total){
                setCurrentPageDataMaps(currentPageDataMaps + 1)
            }
        }
    }

    function prevPage(val) {
        // Vérifier dab si on peut faire suivant
        if(currentPage > 1 && val === 'surveyData'){
            setCurrentPage(currentPage - 1)
        }else{
            if(currentPageDataMaps > 1){
                setCurrentPageDataMaps(currentPageDataMaps - 1)
            }
        }
    }

    const refreshProjects = async () => {
        try {
          const qs = objectToQueryString({
              page : 1,
              limit : 1000000,
          });
    
          if(loadingProject === false){
            setLoadingProject(true)
            await http.get(`/projects${qs}`).then(function(response){
              console.log("response 0000", response)
              if(response.status === 200){
                let res = response?.data?.data?.filter(x => x?.status?.name === 'Running' || x?.status?.name === 'Completed')
                setProjectApplication(res);
                // setPagination(prev => { return {...prev, total : response.data.totalPage}})
                setLoadingProject(false)
              }
            })
          }                                 
        } catch (err) {
            console.log(err)
        }
    };

    console.log('filterValues', filterValues)

    const getSurveyData = async () => {
        setIsLoaded(true)
        const qs = objectToQueryString({
            page : currentPage,
            limit : elementToList?.id,
            projectId: selectedProject?.id,
            searchField: elToSearch,
            statusIds: filterValues?.statusIds?.map(x => x?.value),
            agentIds: filterValues?.agentIds?.map(x => x?.value),
            numbers: filterValues?.numbers,
            createdAt: filterValues?.createdAt ?? null
        });
        console.log('querySearch qs', qs)
        const qsWithoutLimit = objectToQueryString({
            page : 1,
            limit : 500,
            projectId: selectedProject?.id,

            // searchField: elToSearch,
            // statusId: filterValues?.statusId,
            // agentId: filterValues?.agentId,
            // number: filterValues?.number,
            // createdAt: filterValues?.createdAt ?? null
        });

        await http.get(`/find-surveydatas-by-project${qs}`).then(function(response){
            console.log("response getSurveyData", response)
            if(response.status === 200){
                setSurveyData(response.data.data)
                setPagination(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                setIsLoaded(false)
            }
        })

        await http.get(`/find-surveydatas-by-project${qsWithoutLimit}`).then(function(response){
            console.log("response getSurveyData", response)
            if(response.status === 200){
                setSurveyDataWithOutLimit(response.data.data)
                setPaginationExport(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                // setIsLoaded(false)
            }
        })
    }

    const getDataMapps = async () => {
        setIsLoadedDataMaps(true)
        const qs = objectToQueryString({
            page : currentPageDataMaps,
            limit : elementToListDataMaps.id,
        });
        await http.get(`/datas-map/${qs}`).then(function(response){
            console.log("response getDataMapps", response)
            if(response.status === 200){
                setDatasMap(response.data.data)
                setPaginationDataMaps(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                setIsLoadedDataMaps(false)
            }
        })
    }


    const refresh = () => {
        refreshProjects();
    }

    useEffect(() => {
        refresh();
    }, [])
    
    useEffect(() => {
        if(selectedProject?.id !== 'moduleMapping') {
            getSurveyData();
        }
    }, [selectedProject, currentPage, elementToList, filterByStatus])

    useEffect(() => {
        getDataMapps();
    }, [currentPageDataMaps, elementToListDataMaps])


    useEffect(() =>{
        if(selectedView?.id == 'map'){
            navigate("/adm/data-hub/map-view", { replace: false });
        }
    }, [selectedView])

    // // ------- Get Info Of External project ---------------
    // const getExternalProject = async (id) => {
    //     await http.get(`/projects/${id}`).then(function(response){
    //         console.log("response oooo", response)
    //         if(response.status === 200){
    //             setExternalProject(response.data.data)
    //         }
    //     })
    // }

    // useEffect(() => {
    //     if(selectedProject?.configuration?.useExternalProject) {
    //         getExternalProject(selectedProject?.configuration?.externalProject?.id)
    //     }
    // }, [selectedProject])

    console.log('datasMap', datasMap)


    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }

    {/** ------ Start Format Data For excel and csv file */}
    const [dataAA, setDataAA] = useState([])
    useEffect(()=>{
        let data = []
        data = formatedDataForCsvAndXlsxFile(surveyDataWithOutLimit, downloadInfo.from, downloadInfo.to, displayStatusName, selectedProject)
        setDataAA(data)   
        console.log('datadatadata', data)         
    }, [surveyDataWithOutLimit, downloadInfo, selectedProject])

    const [headers, setHeaders] = useState([])
    const [filename, setFilename] = useState('suvery')
    useEffect(()=>{
        if(selectedProject?.id !== 'moduleMapping') {
            setFilename(`${selectedProject?.name.replaceAll(" ", "_")}_survey.csv`)
            
            let dataHeaders = formatedHeadersXlsxAndCsv(selectedProject)
            setHeaders(dataHeaders)
        }
    }, [selectedProject])
        
    // let filename = `${item?.name.replaceAll(" ", "_")}_survey.csv`;

    {/** ------ End Format Data For excel and csv file */}

    {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

    const [formatDataGeolocation, setFormatDataGeolocation] = useState([])
    useEffect(()=>{
        let data 
        data = formatedDataForGeolocationFile(surveyDataWithOutLimit,downloadInfo.from, downloadInfo.to)
        setFormatDataGeolocation(data)

        console.log("datadata", data)
    }, [surveyDataWithOutLimit, downloadInfo])

    {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

    {/* ----- Start format data module mapping **/}

    const headersModuleMapping = [
        { label: "Name", key: "name" },
        { label: "Description", key: "description" },
        { label: "GPS used for Mapping", key: "gpsType" },
        { label: "Device name", key: "deviceName" },
        { label: "Mapping type", key: "mappingType" },
    ]

    const [formatedDataMapps, setFormatedDataMapps] = useState([])
    const [formatedDataMappsGeolocation, setFormatedDataMappsGeolocation] = useState([])

    useEffect(() =>{
        let data = []
        datasMap?.map((x, k) => {
            if(k >= downloadInfo.from - 1 && k <= downloadInfo.to - 1){
                data.push(x)
            }
        })
        setFormatedDataMapps(data)

        let dataGeolocation
        dataGeolocation = formatedDataForGeolocationFileForModuleMapping(datasMap, downloadInfo.from, downloadInfo.to)
        setFormatedDataMappsGeolocation([dataGeolocation])

        console.log('dataGeolocation', dataGeolocation)
    }, [datasMap, downloadInfo])

    {/* ----- Start format data module mapping **/}

    const exportData = async () => {
        // const linkUrl = `https://us-central1-agritech-survey-dev.cloudfunctions.net/api/v1/export-survey-datas?projectId=${selectedProject.id}&typeFile=${downloadInfo.format}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`

        // const linkUrl = `https://us-central1-agritech-survey-preprod-env.cloudfunctions.net/api/v1/export-survey-datas?projectId=${selectedProject.id}&typeFile=${downloadInfo.format}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`

        const linkUrl = `https://us-central1-agritech-survey-mapping.cloudfunctions.net/api/v1/export-survey-datas?projectId=${selectedProject.id}&typeFile=${downloadInfo.format}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`
        
        window.open(linkUrl, '_blank');
    }

    const exportDataGeoLocation = async () => {

        // const linkUrl = `https://us-central1-agritech-survey-dev.cloudfunctions.net/api/v1/download-gpx-or-kml-zip?projectId=${selectedProject.id}&type=${downloadInfo.format}&mappingFieldName=${downloadInfo.concernedField}&status=${downloadInfo.surveysStatus.join(",")}&renameExportData=${formatStringRenameExport(downloadInfo?.fileName, selectedProject?.configuration?.externalProject?.fieldName)}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`

        // const linkUrl = `https://us-central1-agritech-survey-preprod-env.cloudfunctions.net/api/v1/download-gpx-or-kml-zip?projectId=${selectedProject.id}&type=${downloadInfo.format}&mappingFieldName=${downloadInfo.concernedField}&status=${downloadInfo.surveysStatus.join(",")}&renameExportData=${formatStringRenameExport(downloadInfo?.fileName, selectedProject?.configuration?.externalProject?.fieldName)}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`

        const linkUrl = `https://us-central1-agritech-survey-mapping.cloudfunctions.net/api/v1/download-gpx-or-kml-zip?projectId=${selectedProject.id}&type=${downloadInfo.format}&mappingFieldName=${downloadInfo.concernedField}&status=${downloadInfo.surveysStatus.join(",")}&renameExportData=${formatStringRenameExport(downloadInfo?.fileName, selectedProject?.configuration?.externalProject?.fieldName)}&page=${downloadInfo.page}&limit=${downloadInfo.limit}`
        
        window.open(linkUrl, '_blank');
    }

        const [externalProjectData, setExternalProjectData] = useState([])
        // ------- Get Info Of External project ---------------
        const getExternalProjectSurvey = async (id) => {
            // setIsLoadedExternalProjectData(true)
    
            const qs = objectToQueryString({
                page : 1,
                limit : 20,
                projectId: id,
                statusId: null
            });
    
            await http.get(`/find-surveydatas-by-project${qs}`)
            .then(function(response){
                console.log("response survey external project", response)
                if(response.status === 200){
                    let keys = selectedProject?.configuration?.externalProject?.surveyDatasKeysUsed
                    let formatResponseData = response.data.data.map(el => (
                        {
                            value: el.id,
                            name: keys?.map(k => el?.survey[k]?.value)?.join(" ")
                        }
                    ))
                    let tmp = externalProjectData
                    setExternalProjectData([...tmp, ...formatResponseData])
                    // setPaginationExternalProjectData(prev => { return {...prev, total : response.data.totalPage, totalCount: response.data.totalCount}})
                    // setIsLoadedExternalProjectData(false)
                }
            }).catch(err => {
                // setIsLoadedExternalProjectData(false)
            })
        }
    
        const getExternalProject = async (id) => {
            await http.get(`/projects/${id}`).then(function(response){
                console.log("response oooo", response)
                if(response.status === 200){
                    setExternalProject(response.data.data)
                }
            })
        }
    
        useEffect(() => {
            if(selectedProject?.configuration?.useExternalProject) {
                getExternalProjectSurvey(selectedProject?.configuration?.externalProject?.id)
                getExternalProject(selectedProject?.configuration?.externalProject?.id)
            }
        }, [selectedProject])

    
    return(
        <>
            <div className="flex items-center justify-between mb-[50px]">
                <div className="max-w-[40%]">
                    <Heading title={t("common:datahub")} mb={"10px"} />
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                        <span className="text-[14px] text-black text-opacity-50">{t("common:projects")}:</span>
                        <Listbox value={selectedProject} onChange={setSelectedProject}>
                            <div className="relative">
                                <Listbox.Button className="inline-flex h-[40px] w-[300px] justify-between items-center px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    <span className="block text-[14px] whitespace-nowrap text-[#676D7C] mr-8 font-medium overflow-hidden">{selectedProject?.name}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >   
                                    <Listbox.Options className="absolute left-0 w-full mt-2 z-50 bg-white overflow-hidden rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="h-[300px] scrollbar-v overflow-x-hidden">
                                            {projectsApplications?.map((item, itemIdx) => (
                                                <Listbox.Option
                                                    key={itemIdx}
                                                    onClick={() => {
                                                        resetFilter()
                                                        setViewModuleMapping(false)
                                                    }}
                                                    className={({ active }) =>
                                                        `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                            active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                        } ${selectedProject?.id == item?.id ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'}`
                                                    }
                                                    value={item}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block text-[14px] whitespace-nowrap truncate  ${
                                                                    selected ? 'font-normal' : 'font-normal'
                                                                }`}
                                                            >
                                                                {item?.name}
                                                            </span>
                                                            <span className='text-[12px] text-black text-opacity-50'>
                                                                {/* {"status: "} */}
                                                                <span className='text-[12px] text-primary-blue-500 text-opacity-100 font-bold'>
                                                                    {
                                                                        `${item?.status?.name} 
                                                                        - ${item?.countSurveys} 
                                                                    `} 
                                                                    <span className="text-black/50">saisie(e)</span>
                                                                </span>
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </div>
                                        <Listbox.Option
                                            onClick={() => {
                                                resetFilter()
                                                setViewModuleMapping(true)
                                            }}
                                            className={({ active }) =>
                                                `cursor-pointer select-none relative py-4 pl-4 pr-4 ${
                                                    active ? 'bg-primary-blue-500' : 'text-gray-900'
                                                } ${selectedProject?.id == "moduleMapping" ? 'bg-primary-blue-500 !text-white' : 'text-gray-900 bg-gray-50'}`
                                            }
                                            value={{
                                                id: "moduleMapping",
                                                name: "Module Mapping"
                                            }}
                                        >
                                            {({ selected }) => (
                                                <div className='flex items-center gap-[4px]'>
                                                    <BiCurrentLocation className='text-[25px]' />
                                                    <span
                                                        className={`block text-[14px] whitespace-nowrap truncate  ${
                                                            selected ? 'font-normal' : 'font-normal'
                                                        }`}
                                                    >
                                                        {"Module mapping"}
                                                    </span>
                                                </div>
                                            )}
                                        </Listbox.Option>
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-[14px] text-black text-opacity-50">{t("common:modeview")}:</span>
                        <Listbox value={selectedView} onChange={setSelectedView}>
                            <div className="relative">
                                <Listbox.Button className="inline-flex h-[40px] min-w-[98px] justify-between items-center w-full px-2 py-2 text-sm text-gray-500 rounded-[6px] bg-[#fff] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    <span className="block text-[14px] text-[#676D7C] mr-8 font-medium">{selectedView?.name}</span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 8.5L12 15.5L5 8.5" stroke="#C4C4C4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute left-0 w-48 mt-2 z-50 bg-white rounded-[6px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {dataView?.map((item, itemIdx) => (
                                            <Listbox.Option
                                                key={itemIdx}
                                                className={({ active }) =>
                                                    `cursor-pointer select-none relative py-2 pl-4 pr-4 ${
                                                        active ? 'bg-[#00c28818] text-myGreen' : 'text-gray-900'
                                                    }`
                                                }
                                                value={item}
                                            >
                                                {({ selected }) => (
                                                    <>
                                                        <span
                                                            className={`block text-[14px] ${
                                                                selected ? 'font-normal' : 'font-normal'
                                                            }`}
                                                        >
                                                            {item.name}
                                                        </span>
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <div 
                        onClick={() => {
                            viewModuleMapping ?
                            getDataMapps()
                            :
                            getSurveyData()
                        }}
                        className="h-[40px] w-[40px] hover:bg-primary-blue-500 hover:bg-opacity-20 hover:text-primary-blue-500 transition-all ease-out duration-200  cursor-pointer rounded-full flex items-center justify-center"
                    >
                        <BiRefresh className='text-[24px]' />
                    </div>
                    <ButtonWithBg 
                        title={t('project:exportdata')}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={() => {
                            setModalMode('export_data')
                            toggleModal()
                        }}
                    />
                </div>
            </div>
            
            {
                viewModuleMapping ? 
                <ModuleMappingTable 
                    item={datasMap} 
                    t={t} 
                    currentPageDataMaps={currentPageDataMaps} 
                    setCurrentPageDataMaps={setCurrentPageDataMaps} 
                    elementToListDataMaps={elementToListDataMaps} 
                    setElementToListDataMaps={setElementToListDataMaps} 
                    paginationDataMaps={paginationDataMaps} 
                    nextPage={nextPage} prevPage={prevPage} 
                    dataPage={dataPage} 
                    isLoadedDataMaps={isLoadedDataMaps} 
                    userStudyArea={userStudyArea}
                />
                :
                <Table 
                    isLoaded={isLoaded} 
                    item={selectedProject} 
                    surveyDatas={surveyData} 
                    selectedProject={selectedProject} 
                    getSurveyData={getSurveyData} 
                    t={t} 
                    nextPage={nextPage} 
                    prevPage={prevPage} 
                    pagination={pagination} 
                    currentPage={currentPage} 
                    setElementToList={setElementToList} 
                    elementToList={elementToList} 
                    setCurrentPage={setCurrentPage} 
                    dataPage={dataPage} 
                    userStudyArea={userStudyArea}
                    setAgentInfo={setAgentInfo}
                    toggleOpenModal={toggleOpenModal}
                    filterByStatus={filterByStatus}
                    setFilterByStatus={setFilterByStatus}
                    accessControl={controlAccess(selectedProject?.projectUsers, user)}
                    externalProject={externalProject}
                    externalProjectData={externalProjectData}
                    elToSearch={elToSearch}
                    setElToSearch={setElToSearch}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            }

            {/** Modal */}
            <Modal show={isOpen} toggleModal={toggleModal}>
                <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                    <h4>
                        {
                            modalMode == 'update_history' ? "Update history" :
                            modalMode == 'view_data' ? "View data info" : 
                            modalMode == 'export_data' ? "Export data" : 
                            null
                        }
                    </h4>
                    <div 
                        onClick={()=>{toggleModal()}}
                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                    </div>
                </div>
                {   modalMode=='view_data' &&
                    <>
                        <div className="p-6 max-h-[400px] overflow-x-hidden">
                            <div className='grid grid-cols-2 gap-4'>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                    <span className='block text-[14px] text-black'>zongo ousmane</span>
                                </div>
                                <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                    <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                    <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                </div>
                            </div>
                            <div className="pt-8">
                                <span className='block mb-1 text-[14px] text-black text-opacity-50'>Agent Investigator</span>
                                <div className="flex items-center gap-2 py-2">
                                    <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                        <img
                                            src="/images/avatar.png"
                                            alt="profil"
                                            className="h-full w-full object-cover"
                                        />
                                    </div>
                                    <div>
                                        <h4 className="text-[14px] text-black  leading-[15px]">Bah Ismaila</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={'Save change'}
                                type='submit'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => toggleModal()}
                            />
                        </div>
                    </>
                }
                {
                    modalMode=='update_history' &&
                    <>
                        <div className="p-6 max-h-[400px] overflow-x-hidden">
                            <Disclosure className=' mb-3'>
                                {({ open }) => (
                                    <div className={`rounded-lg overflow-hidden } `}>
                                        <Disclosure.Button className={`transition ease-in duration-400 flex items-center justify-between py-2 cursor-pointer w-full border-b border-dashed border-gray-200`}>
                                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                                <span className={`
                                                    text-[14px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                                }`}>
                                                    {"Update of 12-12-2020"}
                                                </span>
                                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                                            </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className=' py-4 bg-opacity-70'>
                                            <div>
                                                <div className='grid grid-cols-2 gap-4'>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                        <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                        <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                        <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                        <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2 py-2 pt-8">
                                                    <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                                        <img
                                                            src="/images/avatar.png"
                                                            alt="profil"
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[14px] text-black  leading-[15px]">Bah Ismaila</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>
                            <Disclosure className=' mb-3'>
                                {({ open }) => (
                                    <div className={`rounded-lg overflow-hidden } `}>
                                        <Disclosure.Button className={`transition ease-in duration-400 flex items-center justify-between py-2 cursor-pointer w-full border-b border-dashed border-gray-200`}>
                                            <div className={`flex items-center justify-between gap-3 w-full`}>
                                                <span className={`
                                                    text-[14px] text-[#454459] font-title leading-[24px] text-left transition ease-in duration-200 font-medium
                                                }`}>
                                                    {"Update of 12-12-2020"}
                                                </span>
                                                <BsChevronRight className={`text-[#454459] text-[14px] font-title font-bold transition-all ease-out duration-150 ${open ? 'rotate-90' : 'rotate-0'}`} />
                                            </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className=' py-4 bg-opacity-70'>
                                            <div>
                                                <div className='grid grid-cols-2 gap-4'>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                        <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                        <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Nom du planteur</span>
                                                        <span className='block text-[14px] text-black'>zongo ousmane</span>
                                                    </div>
                                                    <div className='pb-2 border-b border-dashed border-black border-opacity-10'>
                                                        <span className='block mb-1 text-[14px] text-black text-opacity-50'>Prefecture</span>
                                                        <span className='block text-[14px] text-black capitalize'>aristide yao</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-2 py-2 pt-8">
                                                    <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                                        <img
                                                            src="/images/avatar.png"
                                                            alt="profil"
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </div>
                                                    <div>
                                                        <h4 className="text-[14px] text-black  leading-[15px]">Bah Ismaila</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </Disclosure.Panel>
                                    </div>
                                )}
                            </Disclosure>
                        </div>
                    </>
                }
                {
                    modalMode=='export_data' &&
                    <>
                        <ModalExportData
                            downloadInfo = {downloadInfo}
                            setDownloadInfo = {setDownloadInfo}
                            convertToCSV = {convertToCSV}
                            convertToXLSX = {convertToXLSX}
                            handleClick = {handleClick} 
                            dataAA = {dataAA}
                            headers = {headers}
                            filename = {filename}
                            item = {selectedProject}
                            datasMap={formatedDataMapps}
                            viewModuleMapping={viewModuleMapping}
                            headersModuleMapping={headersModuleMapping}
                            formatDataGeolocation = {formatDataGeolocation}
                            formatedDataMappsGeolocation={formatedDataMappsGeolocation}

                            exportData={exportData}
                            exportDataGeoLocation={exportDataGeoLocation}

                            surveyDatasStatus={surveyDatasStatus}
                            externalProject={externalProject}

                            pagination={paginationExport}
                        />
                    </>
                }
            </Modal>

            {/* View agent infos */}
            <ModalUserInfo 
                user={agentInfo}
                openModal={openModal}
                closeModal={toggleOpenModal}
                t={t} 
            />
        </>
    )
}