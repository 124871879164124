import { GoogleMap, useLoadScript, Marker, Polygon, Polyline, InfoWindow, LoadScript } from "@react-google-maps/api"
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DataMappOption from "./DataMappOptions";
import { handleClick} from "../../../utils/downloadGeolocation";
import { ButtonWithBg, FormikSelect } from "../../../components/Kits";
import { Modal } from "../../../components/Modals";
import { AiOutlineClose } from 'react-icons/ai'
import { getTotalSurface } from "../../../utils/geolibCalcul";
import { useSelector } from "react-redux";
import { MdLocationOn, MdOutlineLocationOff } from "react-icons/md";
import { FaInfo, FaMap, FaPencilAlt } from "react-icons/fa";
import { formatDataSurvey } from "../../../utils/formatSurveyInfo";
import { getSurveyId, retirerId } from "../../../utils/retireId";
import { formatNameSurvey } from "../../../utils/formatNameSurvey";
import InfosParcelle from "./modals/InfosParcelle";
import LocationMappModal from "../LocationData/LocationDataModal";
import { objectToQueryString, removeEmpty } from "../../../utils/table-manager";
import http from "../../../http-common";
import UpdateSurveyForm from "../../../layout/forms/updateSurveyForm";
import { useToaster } from "../../../contexts/toaster";
import UpdateSurveyStatus from "../../../layout/forms/updateSurveyStatus";

export default function Mapps(){
    const toaster = useToaster()
    const { t } = useTranslation(["common", "project", "form"]);
    const userStudyArea = useSelector((state) => state.userStudyArea)
    const surveyDatasStatus = useSelector((state) => state.surveyDatasStatus);
    const [showSurveyStateList, setShowSurveyStateList] = useState(false)
    const [surveyDataRequest, setSurveyDataRequest] = useState({
        surveyDatasId: "",
        description: "",
        statusId: "",
    });
    const [updateStateType, setUpdateStateType] = useState(null)

    const [project, setProject] = useState(null)
    const [drawerType, setDrawerType] = useState(null)
    const [dataMapps, setDataMapps] = useState([])
    const [elementSelected, setElementSelected] = useState([])
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [selectedMarkerInfos, setSelectedMarkerInfos] = useState(null);
    const [delimiter, setDelimiter] = useState('continue')
    let [isOpen, setIsOpen] = useState(false)
    let [modalMode, setModalMode] = useState()
    const toggleModal = ()=>{
        setIsOpen(!isOpen)
    }
    const [showMapType, setShowMapType] = useState(false)
    const [mapType, setMapType] = useState('roadmap')

    const displayStatusName = (id) => {
        let name = "";
        let status = surveyDatasStatus.find((s) => s.id === id);
    
        if (status !== undefined) name = status.name;
        return name;
    };

    // state infos à afficher
    const [showBoxInfoToShow, setShowBoxInfoToShow] = useState(false)
    const [showCenter, setShowCenter] = useState(true)
    const [fieldsInfoToShow, setFieldInfoToShow] = useState([])
    const baseFields = [
        {
            label: `Id (Mapping)`,
            value: `number`,
        },
        {
            label: `Date de création`,
            value: `createdAt`,
        },
        {
            label: `Agent`,
            value: `agent`,
        }
    ]

    const handleFieldsToShow = (el) => {
        let foundEl = fieldsInfoToShow.find(x => x.value === el.value)
        if(foundEl){
            let tmp = fieldsInfoToShow.filter(x => x.value !== el.value)
            setFieldInfoToShow(tmp)
        } else {
            setFieldInfoToShow([...fieldsInfoToShow, el])
        }
    }



    {/** --------------- Start Params to export file  */}
    const [downloadInfo, setDownloadInfo] = useState({
        type: "geolocation",
        format: 'gpx',
        from: 1,
        to: dataMapps?.length
    });

      useEffect(() => {
        setDownloadInfo({
            ...downloadInfo,
            to: dataMapps?.length
        })
      }, [dataMapps])

    {/** --------------- End Params to export file  */}

    const handleMarkerClick = (marker, type) => {
        console.log("marker", marker)
        let formatData = null
        // let infosEl = null
        if(type === 'marker'){
            formatData= {
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude)
            }
        }else{
            formatData = marker.center ?? marker
            setSelectedMarkerInfos(marker?.surveyInfos)
            // infosEl = infos
        }
      setSelectedMarker(formatData);
    //   setSelectedMarkerInfos(infosEl)
    };

    
    const { isLoaded } = useLoadScript({
        googleMapsApiKey : 'AIzaSyD5N5H922bRZG_OuPBAv18tM9xNqiMpzRg'
    }, [])

    const paths = (dataMapps && dataMapps?.length > 0 && drawerType !== 'userLocation') ? dataMapps?.map((data) =>(
        data?.coordinates?.map(coord =>(
            { lat: parseFloat(coord?.latitude), lng: parseFloat(coord?.longitude)}
        ))
    )) : []

    const [coordinatesWithCenter, setCoordinatesWithCenter] = useState((dataMapps && dataMapps?.length > 0 && drawerType !== 'userLocation') ? dataMapps?.map((data) =>(
        {
            center : {lat: data?.mappingCenter?.latitude, lng: data?.mappingCenter?.longitude},
            surveyInfos: data?.surveyInfos
        }
    )) : [])

    useEffect(() => {
        setCoordinatesWithCenter((dataMapps && dataMapps?.length > 0 && drawerType !== 'userLocation') ? dataMapps?.map((data) =>(
            {
                center : {lat: data?.mappingCenter?.latitude, lng: data?.mappingCenter?.longitude},
                surveyInfos: data?.surveyInfos
            }
        )) : [])
    }, [dataMapps, drawerType])

    const [center, setCenter] = useState({lat: 5.354536, lng: -4.037436}) 

    // const center = drawerType === 'userLocation' ? 
    //     {lat: parseFloat(dataMapps?.[0]?.coordinates?.latitude), lng: parseFloat(dataMapps?.[0]?.coordinates?.longitude)} 
    //     : (
    //         coordinatesWithCenter.length === 1 ? coordinatesWithCenter[0]?.center : {lat: parseFloat(dataMapps?.[0]?.coordinates[0]?.latitude), lng: parseFloat(dataMapps?.[0]?.coordinates[0]?.longitude)}
    //     )

    // useEffect(() => {
    //     let tmp
    //     if(drawerType === 'userLocation'){
    //         // setCenter({lat: parseFloat(dataMapps?.[0]?.coordinates?.latitude), lng: parseFloat(dataMapps?.[0]?.coordinates?.longitude)})
    //         tmp={lat: parseFloat(dataMapps?.[0]?.coordinates?.latitude), lng: parseFloat(dataMapps?.[0]?.coordinates?.longitude)}
    //         console.log("tmptmptmp 1", tmp)
    //     } else {
    //         if(coordinatesWithCenter.length === 1){
    //             tmp=coordinatesWithCenter[0]?.center
    //             console.log("tmptmptmp 2", tmp)
    //         } else {
    //             tmp= {lat: parseFloat(dataMapps?.[0]?.coordinates[0]?.latitude), lng: parseFloat(dataMapps?.[0]?.coordinates[0]?.longitude)}
    //             console.log("tmptmptmp 3", tmp, dataMapps)
    //         }
    //     }

    //     setCenter(tmp)
    // }, [coordinatesWithCenter])

    // console.log("centercenter", center)
        
      
        const options = {
            fillColor: "#0b449a2d",
            fillOpacity: 1,
            strokeColor: "#0B449A",
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1
        }
        const optionsSatellite = {
            fillColor: "#0b449a2d",
            fillOpacity: 1,
            strokeColor: "red",
            strokeOpacity: 1,
            strokeWeight: 4,
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1
        }

        const centerOptions = {
            icon: {
              url: '/images/pin.png',
              scaledSize: { width: 24, height: 24 }
            }
        };

        const markerOptions = {
            icon: {
              url: '/images/pin.png',
              scaledSize: { width: 24, height: 24 }
            }
        };

        const markerStartPoint = {
            icon: {
              url: '/images/start-pin.png',
              scaledSize: { width: 24, height: 24 }
            }
        };
        const markerEndPoint = {
            icon: {
              url: '/images/end-pin.png',
              scaledSize: { width: 24, height: 24 }
            }
        };


    {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

    const [formatDataGeolocation, setFormatDataGeolocation] = useState([])
    useEffect(()=>{
        let data 
        data = [dataMapps]
        setFormatDataGeolocation(data)

        console.log('dataMapps 8888 daa', data)

    }, [dataMapps, downloadInfo])

    {/** ------ Start Format Data For gpxTrack , GpxWaypoint and kml file */}

    // console.log('dataMapps simple', dataMapps)
    // console.log('paths simple', paths)
    // console.log('paths simple cooo', coordinatesWithCenter)

    console.log('project mapps', project)

    const [externalProjectData, setExternalProjectData] = useState([])
    const [externalProject, setExternalProject] = useState(null)
    const [externalProjectInfo, setExternalProjectInfo] = useState(null)
    const [isLoadExternalProjectInfo, setIsLoadExternalProjectInfo] = useState(false)
    const [getSd, setGetSd] = useState(null)
    const [dataMappsToShow, setDataMappsToShow] = useState([])
    const [activeTabs, setActiveTabs] = useState('parcelle')
    const [showUpdateFormProject, setShowUpdateFormProject] = useState(false)
    const [updateSurveyState, setUpdateSurveyState] = useState(false)
    const [initialValues, setInitialValues] = useState(null)
    const [isDataLoading, setIsLoadingData] = useState(false)
    const [isDataLoadMore, setIsDataLoadMore] = useState(false)
    const [totalData, setTotalData] = useState(0)
    const [dataCount, setDataCount] = useState(0)

    console.log("dataCount", dataCount)

    // const toaster = useToaster()
    const [mousePosition, setMousePosition] = useState({ lat: null, lng: null });

    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
    
        const coords = `Lat: ${lat}, Lng: ${lng}`;
        navigator.clipboard.writeText(coords)
          .then(() => {
            toaster.show({
                title: 'Copie de coordonnées !',
                message: `Les coordonnées ont été copiées dans le presse-papiers !`,
                greedy: true,
                type: 'info'
            });
          })
          .catch(err => {
            console.error('Erreur lors de la copie dans le presse-papiers : ', err);
          });
    }, []);

    const handleMouseMove = useCallback((event) => {
        // console.log("event", event)
        // event.preventDefault()
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMousePosition({ lat, lng });
    }, []);

    
    const initializeValues = (values, type) => {
        console.log('valuesvalues', values)
        console.log('valuesvalues project', project)
        console.log('valuesvalues type', type)
        if(values && type === 'parcelle') {
            let obj = Object.assign({}, ...Object.entries(values?.survey).map(([key, value], k) => {
                if(project?.configuration?.externalProject?.fieldName && (String(project?.configuration?.externalProject?.fieldName)?.toLocaleLowerCase() === key)) {
                    return {
                        [formatNameSurvey(project?.configuration?.externalProject?.fieldName)]: getSurveyId(value?.value)
                    }
                } else {
                    return {
                        [key]: getSurveyId(value?.value)
                    }
                }   
            }))
            setInitialValues(obj)
        } else if(values && type === 'externalProject') {
            let obj = Object.assign({}, ...Object.entries(values?.survey?.externalProjectData?.surveyDatas).map(([key, value], k) => {
                if(externalProject?.configuration?.externalProject?.fieldName && (String(externalProject?.configuration?.externalProject?.fieldName)?.toLocaleLowerCase() === key)) {
                    return {
                        [formatNameSurvey(externalProject?.configuration?.externalProject?.fieldName)]: getSurveyId(value?.value)
                    }
                } else {
                    return {
                        [key]: getSurveyId(value?.value)
                    }
                }   
            }))
            setInitialValues(obj)
        } else{
            setInitialValues(null)
        }
    }

    const getSurveyById = async (id, surveyDataEL) => {
        // console.log('je suis dans la function')
        setIsLoadExternalProjectInfo(true)
        await http.get(`/survey-datas/${id}`)
        .then((response) => {
            console.log('response ddd', response)
            if(surveyDataEL){
                let formatWithSurvey = {
                    ...surveyDataEL,
                    survey: {
                        ...surveyDataEL?.survey,
                        externalProjectData: response?.data?.data,
                        // externalProjectData: response?.data?.data?.surveyDatas
                    }
                }
                setGetSd(formatWithSurvey)
                setExternalProjectInfo(response?.data?.data)
                toggleModal()
            } else{
                setExternalProjectInfo(response?.data?.data)
                setIsLoadExternalProjectInfo(false)
            }
        })
        .catch((err) => {
            setIsLoadExternalProjectInfo(false)
            if(surveyDataEL){
                setGetSd(surveyDataEL)
            }
        })
    }

    console.log('externalProjectInfo', externalProjectInfo)

    // ------- Get Info Of External project ---------------
    const getExternalProjectSurvey = async (id) => {
        const qs = objectToQueryString({
            page : 1,
            limit : 20,
            projectId: id,
            statusId: null
        });

        await http.get(`/find-surveydatas-by-project${qs}`)
        .then(function(response){
            if(response.status === 200){
                let keys = project?.configuration?.externalProject?.surveyDatasKeysUsed
                let formatResponseData = response.data.data.map(el => (
                    {
                        value: el.id,
                        name: keys?.map(k => el?.survey[k]?.value)?.join(" ")
                    }
                ))
                let tmp = externalProjectData
                setExternalProjectData([...tmp, ...formatResponseData])
            }
        }).catch(err => {
            // setIsLoadedExternalProjectData(false)
        })
    }

    const getExternalProject = async (id) => {
        await http.get(`/projects/${id}`).then(function(response){
            if(response.status === 200){
                setExternalProject(response.data.data)
            }
        })
    }

    useEffect(() => {
        if(project?.configuration?.useExternalProject) {
            getExternalProjectSurvey(project?.configuration?.externalProject?.id)
            getExternalProject(project?.configuration?.externalProject?.id)
        }
    }, [project])

    const formatDataToShow = (selectedMarkerInfos) => {
        console.log("selectedMarkerInfos", selectedMarkerInfos)
        if(selectedMarkerInfos?.datasMap?.mapps?.length > 0 ){
            setModalMode("infosParcelle")
            initializeValues(selectedMarkerInfos, 'parcelle')
            setDataMappsToShow({ 
                type : selectedMarkerInfos?.datasMap?.mapps[0]?.type, 
                name: selectedMarkerInfos?.datasMap?.mapps[0]?.label ,  
                coordinates: selectedMarkerInfos?.datasMap?.mapps[0]?.coordinates, 
                centerDataMapps: {
                    lat: selectedMarkerInfos?.datasMap?.mapps[0]?.mappingCenter?.latitude, 
                    lng: selectedMarkerInfos?.datasMap?.mapps[0]?.mappingCenter?.longitude
                } ,
                srv: selectedMarkerInfos
            })

            if(project?.configuration?.useExternalProject){
                let aa = getSurveyId((selectedMarkerInfos?.survey[formatNameSurvey(project?.configuration?.externalProject?.fieldName)]?.value))
                getSurveyById(aa, selectedMarkerInfos)
                toggleModal()
            } else {
                setGetSd(selectedMarkerInfos)
            }

            // if(project?.configuration?.useExternalProject && !selectedMarkerInfos?.survey?.externalProjectData){
            //     let aa = getSurveyId((selectedMarkerInfos?.survey[formatNameSurvey(project?.configuration?.externalProject?.fieldName)]?.value))
            //     getSurveyById(aa, selectedMarkerInfos)
            //     toggleModal()
            // } else {
            //     let aa = getSurveyId((selectedMarkerInfos?.survey[formatNameSurvey(project?.configuration?.externalProject?.fieldName)]?.value))
            //     getSurveyById(aa)
            //     setGetSd(selectedMarkerInfos)
            //     toggleModal()
            // }
        }
    }


    console.log('initialValuesinitialValues', initialValues)
        // ------------------------------------------------------------ GET DATA SOURCE

        const formatData = (data) => {
            return data.map((item) => {
                // Get values from object
                const values = Object.values(item?.dataSource);
                // Join values with ' - '
                const content = values.join(' - ');
                // Return formatted object
                return { content, id: item?.id };
            });
        };
    
        const [datasForSource, setDataForSource] = useState(null)
        const [datasForExternalProjectSource, setDataForExternalProjectSource] = useState(null)
        let dataSourcesToLoad = []
        let dataSourcesToLoadForExternalProject = []

        useEffect(() => {
            if(project){
                project?.form?.fields?.forEach(element => {
                    if(element?.type === 'select-data-source') {
                        console.log('element', element)
                        dataSourcesToLoad.push({name: element?.name, sourceId: element?.values?.[0]?.id})
                    }
                });
            }
        }, [project])

        useEffect(() => {
            if(externalProject){
                externalProject?.form?.fields?.forEach(element => {
                    if(element?.type === 'select-data-source') {
                        dataSourcesToLoadForExternalProject.push({name: element?.name, sourceId: element?.values?.[0]?.id})
                    }
                });
            }
        }, [externalProject])
    
        // Fonction asynchrone pour effectuer la requête HTTP
        const fetchData = async (id) => {
            try {
                const response = await http.get(`/datas-sources-by-form-data-source-id/${id}`);
    
                let createArray = response?.data?.data?.map((el) => (
                    {
                        dataSource: el.dataSource,
                        id: el.id
                    }
                ))
                let finalArray = formatData(createArray)
                // let finalArray = []
                return finalArray;
            } catch (error) {
                console.error(`Erreur lors de la requête pour ${id}:`, error);
                return null;
            }
        };
    
        // Fonction principale pour traiter chaque élément dans dataSourcesToLoad
        const processDataSources = async (datas, setElement) => {
            const promises = datas.map(dataSource => fetchData(dataSource.sourceId));
    
            try {
                // Attendre que toutes les promesses soient résolues
                const results = await Promise.all(promises);
    
                // Construire l'objet résultant
                const ggg = datas.reduce((acc, dataSource, index) => {
                    const { name } = dataSource;
                    acc[name] = results[index];
                    return acc;
                }, {});
    
                setElement(ggg)
            } catch (error) {
                console.error('Erreur lors de l\'exécution de Promise.all:', error);
            }
        };
    
        useEffect(() => {
            if(dataSourcesToLoad?.length > 0){
                processDataSources(dataSourcesToLoad, setDataForSource);
            }
        }, [dataSourcesToLoad])

        useEffect(() => {
            if(dataSourcesToLoadForExternalProject?.length > 0){
                processDataSources(dataSourcesToLoadForExternalProject, setDataForExternalProjectSource);
            }
        }, [dataSourcesToLoadForExternalProject])

        console.log('getSd', getSd)

        const [isUpdatingSurvey, setIsUpdatingSurvey] = useState(false)
        const updateSurveyData = async () => {
            setIsUpdatingSurvey(true)
            delete initialValues?.id
            delete initialValues?.source
            
            let dataToSend

            if(activeTabs === 'externalProject'){
                dataToSend = {
                    surveyDatas: removeEmpty(initialValues),
                    statusId: externalProjectInfo?.statusId,
                    datasMap: {
                        id: externalProjectInfo?.datasMap?.id ? externalProjectInfo?.datasMap?.id : null, 
                        mappings: externalProjectInfo?.datasMap?.mapps ? externalProjectInfo?.datasMap?.mapps : []
                    },
                    userLocation: externalProjectInfo?.userLocation
                }
            } else{
                dataToSend = {
                    surveyDatas: removeEmpty(initialValues),
                    statusId: getSd?.status?.id,
                    datasMap: {
                        id: getSd?.datasMap?.id ? getSd?.datasMap?.id : null, 
                        mappings: getSd?.datasMap?.mapps ? getSd?.datasMap?.mapps : []
                    },
                    userLocation: getSd?.userLocation
                }
            }

            console.log('dataToSenddataToSend', dataToSend)
    
            await http.put(`/survey-datas/${activeTabs === 'externalProject' ? externalProjectInfo?.id : getSd?.id}`, dataToSend)
            .then((response) => {
                setIsUpdatingSurvey(false)
                // console.log('response update', response.data.data.surveyDatas)
                // console.log('response update rrr', response)
                const {externalProjectData, ...restOfResponse} = response.data.data.surveyDatas
                let foundEl = dataMapps.findIndex(x => x?.surveyInfos?.id === getSd?.id)

                if(foundEl >= 0) {
                    const updatedDataMapps = [...dataMapps];
                    updatedDataMapps[foundEl] = {
                        ...updatedDataMapps[foundEl],
                        surveyInfos: {
                            ...updatedDataMapps[foundEl].surveyInfos,
                            survey: {
                                ...updatedDataMapps[foundEl].surveyInfos.survey,
                                ...restOfResponse
                            }
                        }
                    };

                    setDataMapps(updatedDataMapps);
                }
                console.log('foundEl update', foundEl)
                toaster.show({
                    title: 'Success !',
                    message: `Your survey has modified successfully`,
                    greedy: true,
                    type: 'success'
                });
                toggleModal()
                setGetSd(null)
                setShowUpdateFormProject(false)
                setActiveTabs('parcelle')
                // window.location.reload()
            })
            .catch((err) => {
                toaster.show({
                    title: 'Oups !',
                    message: `Something was wrong`,
                    greedy: true,
                    type: 'error'
                });
                setIsUpdatingSurvey(false)
            })
        }

    if(!isLoaded) return <div>Loading mapp...</div>

    console.log('externalProject', externalProject)
    console.log('dataMapps ttt', dataMapps)
    console.log('selectedMarkerInfos', selectedMarkerInfos)

    return (
        <>
            <div className="relative mapView_page">
                <DataMappOption 
                    setIsLoadingData={setIsLoadingData} 
                    setIsDataLoadMore={setIsDataLoadMore} 
                    isDataLoadMore={isDataLoadMore}
                    setTotalData={setTotalData} 
                    setDataCount={setDataCount} 
                    setProject={setProject} 
                    setDrawerType={setDrawerType} 
                    setDataMapps={setDataMapps} 
                    setElementSelected={setElementSelected} 
                />
                <Mapp 
                    center={center}
                    coordinatesWithCenter={coordinatesWithCenter}
                    mapType={mapType}
                    drawerType={drawerType}
                    dataMapps={dataMapps}
                    handleMarkerClick={handleMarkerClick}
                    paths={paths}
                    options={options}
                    optionsSatellite={optionsSatellite}
                    fieldsInfoToShow={fieldsInfoToShow}
                    showCenter={showCenter}
                    centerOptions={centerOptions}
                    delimiter={delimiter}
                    markerOptions={markerOptions}
                    markerStartPoint={markerStartPoint}
                    markerEndPoint={markerEndPoint}
                    selectedMarker={selectedMarker}
                    setSelectedMarker={setSelectedMarker}
                    formatDataToShow={formatDataToShow}
                    selectedMarkerInfos={selectedMarkerInfos}
                    handleMapClick={handleMapClick}
                    handleMouseMove={handleMouseMove}
                    // mousePosition={mousePosition}
                />
                <div className="absolute bottom-[3%] right-[3%] flex items-center">
                    <div className="relative bg-white rounded-lg cursor-pointer group">
                        <div 
                            className="relative h-[40px] w-[40px] bg-white rounded-lg cursor-pointer z-40 flex items-center justify-center"
                        >
                            <FaInfo />
                        </div>
                        <div className="absolute bottom-[100%] w-[200px] right-0 bg-white p-[10px] rounded-lg space-y-[10px] group-hover:block hidden">
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${showCenter ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setShowCenter(!showCenter)}
                            >
                                Centre
                            </span>
                            {
                                project?.form?.fields?.map((el, idx) =>(
                                    <span 
                                        key={idx}
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === el.name) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => handleFieldsToShow({value: el.name, label: el.label})}
                                    >
                                        {el.label}
                                    </span>
                                ))
                            }
                            {
                                project?.configuration?.useExternalProject && (
                                    <span 
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === project?.configuration?.externalProject?.fieldName) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => {
                                            handleFieldsToShow({
                                                label: project?.configuration?.externalProject?.fieldName,
                                                value: project?.configuration?.externalProject?.fieldName
                                            })
                                        }}
                                    >
                                        {project?.configuration?.externalProject?.fieldName}
                                    </span>
                                )
                            }
                            {
                                baseFields?.map((el, idx) =>(
                                    <span 
                                        key={idx}
                                        className={`block text-[14px] text-black font-[400] cursor-pointer rounded-md ${fieldsInfoToShow.find(x => x.value === el.value) ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px]`}
                                        onClick={() => handleFieldsToShow(el)}
                                    >
                                        {el.label}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                    &nbsp;
                    <div className="relative bg-white rounded-lg cursor-pointer group">
                        <div className="relative h-[40px] w-[40px] bg-white rounded-lg cursor-pointer z-40 flex items-center justify-center">
                            <FaMap />
                        </div>
                        <div 
                            onClick={() => {
                                setMapType('roadmap')
                                setShowMapType(!showMapType)
                            }} 
                            className={`absolute top-0 left-0 h-[40px] w-[40px] bg-white border ${mapType === 'roadmap' ? "border-blue-500 border-2" : ''} rounded-lg cursor-pointer transition-transform duration-200 ease-out  group-hover:translate-y-[-110%]`}
                        >
                            <img 
                                src="/images/roadmap.png"
                                alt="icon"
                                className="h-full w-full object-cover rounded-lg"
                            />
                        </div>
                        <div 
                            onClick={() => {
                                setMapType("satellite")
                                setShowMapType(!showMapType)
                            }}
                            className={`absolute top-0 left-0 h-[40px] w-[40px] bg-white border ${mapType === 'satellite' ? "border-blue-500 border-2" : ''} rounded-lg cursor-pointer transition-transform duration-200 ease-out group-hover:translate-y-[-220%]`}
                        >
                            <img 
                                src="/images/satellite.png"
                                alt="icon"
                                className="h-full w-full object-cover rounded-lg"
                            />
                        </div>
                    </div>
                    &nbsp;
                    <div className="relative bg-white rounded-lg cursor-pointer group">
                        <div 
                            className="relative h-[40px] px-[20px] bg-white rounded-lg cursor-pointer z-40 flex items-center justify-center"
                        >
                            <span className="text-[14px]">Délimitation</span>
                        </div>
                        <div className="absolute bottom-[110%] w-[300px] right-0 bg-white p-[10px] rounded-lg space-y-[10px] group-hover:block hidden">
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'continue' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('continue')}
                            >
                                <span className="flex items-center gap-2">
                                    {"Délimitation continue"}
                                </span>
                            </span>
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'continueWitCoords' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('continueWitCoords')}
                            >
                                <span className="flex items-center gap-2">
                                    {"Délimitation continue avec coordonnées"}
                                </span>
                            </span>
                            <span 
                                className={`block text-[14px] text-black font-[400] cursor-pointer ${delimiter === 'startAndEndPoints' ? "bg-primary-blue-500/20" : ""} py-[5px] px-[8px] rounded-md`}
                                onClick={() => setDelimiter('startAndEndPoints')}
                            >
                                <span className="flex items-center gap-2">
                                    {" Points de départ et d’arrivée"}
                                </span>
                            </span>
                        </div>
                    </div>
                    &nbsp;
                    <ButtonWithBg 
                        title={t('project:exportdata')}
                        type='button'
                        bg={'primary'}
                        isSmall={true}
                        onClick={() => {
                            setModalMode('export_data')
                            toggleModal()
                        }}
                    />
                </div>
                {
                    paths && drawerType === 'Polygon' && (
                        <div className="absolute top-[10%] left-[4.5%] bg-white w-[300px] px-6 py-2 rounded-lg shadow-[0_1px_2px_rgba(60,64,67,0.3)] border">
                            <span className={`text-[14px] text-black block py-2 border-b border-dashed`}>
                                {elementSelected === 0 ? 'Aire Totale' : 'Aire'} : <span className="text-primary-blue-500 font-bold">{getTotalSurface(paths, userStudyArea?.area, userStudyArea?.perimeter).aire} {userStudyArea?.area}</span>
                            </span>
                            <span className="text-[14px] text-black block py-2 border-b border-dashed">
                                {elementSelected === 0 ? 'Périmètre Totale' : 'Périmètre'} : <span className="text-primary-blue-500 font-bold">{getTotalSurface(paths, userStudyArea?.area, userStudyArea?.perimeter).perimeter} {userStudyArea?.perimeter}</span>
                            </span>
                            <span className={`text-[14px] text-black block py-2`}>
                                Nombre total : <span className="text-primary-blue-500 font-bold">{dataMapps.length}</span>
                            </span>
                        </div>
                    )
                }
                {
                    isDataLoadMore && (
                        <div className="absolute bottom-[3%] left-[4.5%] flex items-start gap-3 bg-white px-4 pt-[20px] pb-[20px] rounded-lg">
                            <div className="lds-ring z-[200]">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="mt-[-10px]">
                                <span className="text-[14px] block">Réccupération des données en cours...</span>
                                <span className="text-[14px]">{dataCount}/{totalData}</span>
                            </div>
                        </div>
                    )
                }
                {
                    isDataLoading && (
                        <div className="h-full w-full bg-white/70 absolute top-0 left-0 z-[1234567] flex items-center justify-center">
                            <div className="bg-white rounded-lg p-[2rem] flex flex-col justify-center items-center">
                                <div className="lds-ring z-[200] mb-4">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <span className="text-[14px]">Initialisation des données en cours....</span>
                            </div>
                        </div>
                    )
                }
                {mousePosition.lat && mousePosition.lng && (
                    <div 
                        className="absolute bottom-[3%] left-[60px] z-40 rounded-[8px] bg-white/80 p-[10px] flex"
                    >
                        <p className="text-[13px]">Lat: {mousePosition.lat}</p>
                        &nbsp;<p className="text-[13px]">{","}</p>&nbsp;
                        <p className="text-[13px]">Lng: {mousePosition.lng}</p>
                    </div>
                )}
            </div>

            <Modal show={isOpen} toggleModal={() => {
                toggleModal()
                setGetSd(null)
                setShowUpdateFormProject(false)
                setActiveTabs('parcelle')
            }}>
                {
                    modalMode === 'export_data' &&
                    <>
                        <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                            <h4>
                                {t("project:exportdata")}
                            </h4>
                            <div 
                                onClick={()=>{toggleModal()}}
                                className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                                <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                            </div>
                        </div>
                        <div className="p-6 min-h-[250px] overflow-x-hidden">
                            <form>
                                <div className="mb-[30px]">
                                    <label className="text-[14px] text-black text-opacity-50">{t("project:formattoexport")}</label>
                                    <select 
                                        value={downloadInfo?.format} 
                                        onChange={(e)=>{
                                            setDownloadInfo({
                                                ...downloadInfo,
                                                format: e.target.value
                                            })
                                        }}
                                        className="h-[40px] bg-white border rounded-[6px] w-full focus:outline-none text-[14px] px-1">
                                        <option value='gpx'>GPX Waypoint</option>
                                        <option value='kml'>KML</option>
                                        <option value='gpxTrack'>GPX Track</option>
                                    </select>
                                </div>
                                <div className="mb-[30px]">
                                <label className="text-[14px] text-black text-opacity-50">{`Nommage des fichiers`}</label>
                                {/* <FormikSelect
                                    isMulti
                                    options= {project?.configuration?.useExternalProject ? [
                                        ...baseFields,
                                        ...project?.form?.fields?.map(k => (
                                            {
                                                label: k?.label,
                                                value: k?.name
                                            }
                                        )),
                                        {
                                            label: project?.configuration?.externalProject?.fieldName,
                                            value: project?.configuration?.externalProject?.fieldName
                                        }
                                    ] : [
                                        ...baseFields,
                                        ...project?.form?.fields?.map(k => (
                                            {
                                                label: k?.label,
                                                value: k?.name
                                            }
                                        ))
                                    ]
                                    }
                                    value={downloadInfo.fileName}
                                    onChange={(e) => {
                                        console.log('formik log', e)
                                        setDownloadInfo({...downloadInfo, fileName: e})
                                    }}
                                /> */}
                            </div>
                            </form>
                        </div>
                        <div className='flex justify-end mb-[10px] px-6 pt-3 border-t'>
                            <ButtonWithBg 
                                title={t('project:export')}
                                type='button'
                                bg={'primary'}
                                isSmall={true}
                                onClick={() => {
                                    (downloadInfo?.format === 'kml' && downloadInfo?.type === 'geolocation') ? handleClick(formatDataGeolocation, 'kml') : 
                                    (downloadInfo?.format === 'gpx' && downloadInfo?.type === 'geolocation') ? handleClick(formatDataGeolocation, 'gpx') : 
                                    (downloadInfo?.format === 'gpxTrack' && downloadInfo?.type === 'geolocation') ? handleClick(formatDataGeolocation, 'gpxTrack') : 
                                    console.log('first')
                                }}
                            />
                        </div>
                    </>
                }
                {
                    modalMode === 'infosParcelle' && (
                        <>
                            <div className="border-b flex items-center justify-between gap-4 px-6 py-4">
                                <div className="flex items-center gap-4">
                                    <div 
                                        className={`rounded-[6px] cursor-pointer flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize focus:outline-none ${activeTabs === 'parcelle' ? 'bg-primary-blue-500 text-white' : 'bg-primary-blue-500 bg-opacity-10 text-primary-blue-500'}`}
                                        onClick={() => {
                                            setActiveTabs('parcelle')
                                            initializeValues(getSd, 'parcelle')
                                            setUpdateSurveyState(false)
                                        }}
                                    >
                                        {"Mapping"}
                                    </div>
                                    {
                                        project?.configuration && (
                                            <div 
                                                className={`rounded-[6px] cursor-pointer flex items-center justify-center h-[35px] px-[30px] text-sm font-medium leading-5 capitalize focus:outline-none ${activeTabs === 'externalProject' ? 'bg-primary-blue-500 text-white' : 'bg-primary-blue-500 bg-opacity-10 text-primary-blue-500'}`}
                                                onClick={() => {
                                                    setActiveTabs('externalProject')
                                                    initializeValues(getSd, 'externalProject')
                                                    setUpdateSurveyState(false)
                                                }}
                                            >
                                                {project?.configuration?.externalProject?.fieldName}
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="flex items-center gap-[5px] shrink-0">
                                    {
                                        activeTabs === 'externalProject' ? (
                                            <div className="bg-gray-200 px-[12px] py-[8px] rounded-[8px]">
                                                <span className="text-[14px]">
                                                    Id: {getSd?.survey?.externalProjectData?.number ?? '----'}
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="bg-gray-200 px-[12px] py-[8px] rounded-[8px]">
                                                <span className="text-[14px]">
                                                    Id: {getSd?.number ?? '----'}
                                                </span>
                                            </div>
                                        )
                                    }
                                    <div 
                                        onClick={()=>{
                                            toggleModal()
                                            setGetSd(null)
                                            setShowUpdateFormProject(false)
                                            setActiveTabs('parcelle')
                                        }}
                                        className="h-[30px] w-[30px] rounded-full bg-red-300 flex items-center justify-center cursor-pointer">
                                        <AiOutlineClose className="text-[20px] font-bold text-red-500" />
                                    </div>
                                </div>
                            </div>
                            {
                                activeTabs === 'parcelle' && 
                                <>
                                    {
                                        updateSurveyState ? (
                                            <UpdateSurveyStatus 
                                                surveyDataRequest={surveyDataRequest}
                                                setSurveyDataRequest={setSurveyDataRequest}
                                                getSurveyData={null}
                                                toggleModal={() => setUpdateSurveyState(false)}
                                                getSd={getSd}
                                                project={project}
                                                modalMode={updateStateType}

                                                // state survey
                                                setDataMapps={setDataMapps}
                                                dataMapps={dataMapps}
                                                setGetSd={setGetSd}
                                            />
                                        ) : (
                                            <>
                                                {
                                                    showUpdateFormProject ? (
                                                        <div className="p-6 max-h-[400px] overflow-x-hidden">
                                                            <UpdateSurveyForm
                                                                project={project}
                                                                getSd={getSd}
                                                                initialValues={initialValues}
                                                                setInitialValues={setInitialValues}
                                                                datasForSource={datasForExternalProjectSource}
                                                                externalProjectData={externalProjectData}
                                                                typeSurveyExternal={true}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <LocationMappModal 
                                                            state={dataMappsToShow} 
                                                            project={project} 
                                                            externalProject={externalProject} 
                                                            withUpdateOption={true}
                                                        />
                                                    )
                                                }
                                                <div className="flex items-center justify-between px-6 py-3 border-t gap-3">
                                                    <div className="flex items-center gap-2 py-2">
                                                        <div className="h-[32px] w-[32px] flex-shrink-0 rounded-full overflow-hidden">
                                                            <img
                                                                src="/images/avatar.png"
                                                                alt="profil"
                                                                className="h-full w-full object-cover"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h4 className="text-[14px] text-black  leading-[15px]">{getSd?.user?.firstName +" "+ getSd?.user?.lastName}</h4>
                                                        </div>
                                                    </div>
                                                    <div className='flex justify-end gap-3 flex-1'>
                                                        
                                                        <div 
                                                            className="h-[40px] w-[40px] bg-primary-blue-500/10 rounded-lg cursor-pointer flex items-center justify-center"
                                                            onClick={() => setShowUpdateFormProject(!showUpdateFormProject)}
                                                        >   
                                                            {
                                                                showUpdateFormProject ? (
                                                                    <FaMap className="text-primary-blue-500" />
                                                                ) : (
                                                                    <FaPencilAlt className="text-primary-blue-500" />
                                                                )
                                                            }
                                                        </div>
                                                        {
                                                            !showUpdateFormProject &&
                                                            <div className="relative">
                                                                <ButtonWithBg 
                                                                    title={'Statut actuel: ' + t(`project:${getSd?.status?.name?.replaceAll(" ", "")}`)}
                                                                    type='button'
                                                                    bg={'gray'}
                                                                    isSmall={true}
                                                                    onClick={() => setShowSurveyStateList(!showSurveyStateList)}
                                                                />
                                                                {
                                                                    showSurveyStateList &&
                                                                    <div className="absolute bottom-[110%] right-0 h-[200px] w-full bg-white shadow-[0_1px_2px_rgba(60,64,67,0.3)] rounded-lg p-[12px]">
                                                                        {
                                                                            surveyDatasStatus?.map((status, idx) => (
                                                                                <button
                                                                                    onClick={() => {
                                                                                        setSurveyDataRequest({
                                                                                            ...surveyDataRequest,
                                                                                            statusId: status?.id,
                                                                                            surveyDatasId: getSd?.id,
                                                                                            description: '',
                                                                                        });
                                                                                        (status?.name === 'To update') ? setUpdateStateType("order") : setUpdateStateType('otherState')
                                                                                        setUpdateSurveyState(true)
                                                                                        setShowSurveyStateList(!showSurveyStateList)
                                                                                    }}
                                                                                    className={`hover:bg-[#00c28818] hover:text-myGreen text-black flex rounded-md items-center w-full px-2 py-2 text-[12px]  "cursor-pointer`}
                                                                                >
                                                                                    {t(`project:${status?.name?.replaceAll(" ", "")}`)}
                                                                                </button>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            showUpdateFormProject && (
                                                                <>
                                                                    <ButtonWithBg 
                                                                        title={isUpdatingSurvey ? t("common:inprogress") : t("project:savechange")}
                                                                        type='submit'
                                                                        bg={'primary'}
                                                                        isSmall={true}
                                                                        // disabled={isUpdatingSurvey}
                                                                        onClick={() => {
                                                                            // if(!item?.configuration?.useExternalProject)
                                                                            updateSurveyData()
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            }
                            {
                                activeTabs === 'externalProject' && 
                                <>  
                                    {
                                        updateSurveyState ? (
                                            <UpdateSurveyStatus 
                                                surveyDataRequest={surveyDataRequest}
                                                setSurveyDataRequest={setSurveyDataRequest}
                                                getSurveyData={null}
                                                toggleModal={() => setUpdateSurveyState(false)}
                                                getSd={getSd}
                                                project={project}
                                                modalMode={updateStateType}

                                                // state survey
                                                externalProjectInfo={externalProjectInfo}
                                                setExternalProjectInfo={setExternalProjectInfo}
                                            />
                                        ) : (
                                            <>
                                                <div className="p-6 max-h-[400px] overflow-x-hidden">
                                                    <UpdateSurveyForm
                                                        project={externalProject}
                                                        getSd={getSd}
                                                        initialValues={initialValues}
                                                        setInitialValues={setInitialValues}
                                                        datasForSource={datasForExternalProjectSource}
                                                        externalProjectData={externalProjectData}
                                                    />
                                                </div>
                                                <div className='flex items-center justify-between w-full mb-[10px] px-6 pt-3 border-t'>
                                                    {
                                                        (externalProject && externalProject?.projectUsers?.find(x => x?.userId === getSd?.survey?.externalProjectData?.userId)) ?
                                                        <div className="flex items-center gap-2 py-2">
                                                            <div className="h-[40px] w-[40px] flex-shrink-0 rounded-full overflow-hidden">
                                                                <img
                                                                    src="/images/avatar.png"
                                                                    alt="profil"
                                                                    className="h-full w-full object-cover"
                                                                />
                                                            </div>
                                                            <div>
                                                                <h4 className="text-[14px] text-black  leading-[15px]">{externalProject?.projectUsers?.find(x => x?.userId === getSd?.survey?.externalProjectData?.userId)?.firstName +" "+ externalProject?.projectUsers?.find(x => x?.userId === getSd?.survey?.externalProjectData?.userId)?.lastName}</h4>
                                                            </div>
                                                        </div>
                                                        : <span>&nbsp;</span>
                                                    }
                                                    <div className="flex items-center gap-[20px]">
                                                        <div className="relative">
                                                            <ButtonWithBg 
                                                                title={'Statut actuel: ' + t(`project:${displayStatusName(externalProjectInfo?.statusId)?.replaceAll(" ", "")}`)}
                                                                type='button'
                                                                bg={'gray'}
                                                                isSmall={true}
                                                                onClick={() => setShowSurveyStateList(!showSurveyStateList)}
                                                            />
                                                            {
                                                                showSurveyStateList &&
                                                                <div className="absolute bottom-[110%] right-0 h-[200px] z-[30] w-full bg-white shadow-[0_1px_2px_rgba(60,64,67,0.3)] rounded-lg p-[12px]">
                                                                    {
                                                                        surveyDatasStatus?.map((status, idx) => (
                                                                            <button
                                                                                onClick={() => {
                                                                                    setSurveyDataRequest({
                                                                                        ...surveyDataRequest,
                                                                                        statusId: status?.id,
                                                                                        surveyDatasId: externalProjectInfo?.id,
                                                                                        description: '',
                                                                                    });
                                                                                    (status?.name === 'To update') ? setUpdateStateType("order") : setUpdateStateType('otherState')
                                                                                    setUpdateSurveyState(true)
                                                                                    setShowSurveyStateList(!showSurveyStateList)
                                                                                }}
                                                                                className={`hover:bg-[#00c28818] hover:text-myGreen text-black flex rounded-md items-center w-full px-2 py-2 text-[12px]  "cursor-pointer`}
                                                                            >
                                                                                {t(`project:${status?.name?.replaceAll(" ", "")}`)}
                                                                            </button>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <ButtonWithBg 
                                                            title={isUpdatingSurvey ? t("common:inprogress") : t("project:savechange")}
                                                            type='submit'
                                                            bg={'primary'}
                                                            isSmall={true}
                                                            // disabled={isUpdatingSurvey}
                                                            onClick={() => {
                                                                // if(!item?.configuration?.useExternalProject)
                                                                updateSurveyData()
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            }
                        </>
                    )
                }
            </Modal>
        </>
    )
}

const Mapp = ({
    center,
    coordinatesWithCenter,
    mapType,
    drawerType,
    dataMapps,
    handleMarkerClick,
    paths,
    options,
    optionsSatellite,
    fieldsInfoToShow,
    showCenter,
    centerOptions,
    delimiter,
    markerOptions,
    markerStartPoint,
    markerEndPoint,
    selectedMarker,
    setSelectedMarker,
    formatDataToShow,
    selectedMarkerInfos,
    handleMapClick,
    handleMouseMove
}) => {



    return(
        <>
            <GoogleMap 
                center={
                    (center.lat) ? center : {lat: 5.354536, lng: -4.037436}
                }
                mapContainerClassName="map-container"
                zoom={8}
                // zoom={coordinatesWithCenter.length === 1 ? 14 : 8}
                // roadmap
                options={{
                    mapTypeId: mapType,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.BOTTOM_LEFT,
                    },
                }}
                onDblClick={handleMapClick}
                onMouseMove={handleMouseMove}
            >
            {
                drawerType === 'Marker' ? 
                <>
                    {
                        dataMapps?.map((data, i) =>(
                            data?.coordinates?.map(coord =>(
                                <Marker 
                                    key={i} 
                                    position={{ lat: parseFloat(coord?.latitude), lng: parseFloat(coord?.longitude)}} 
                                    onClick={() => handleMarkerClick(coord, 'marker')}
                                />
                            ))
                        ))
                    }
                </>
                :
                drawerType === 'Polygon' ? 
                <>
                    {
                        delimiter !== 'startAndEndPoints' && (
                            <Polygon
                                paths={paths}
                                options={mapType === 'roadmap' ? options : optionsSatellite}
                                onClick={() => console.log(`j'ai cliqué`)}
                            />
                        )
                    }
                    
                    {
                        fieldsInfoToShow?.length > 0 && (
                            coordinatesWithCenter?.map((coords, idx) =>(
                                <InfoWindow
                                    position={coords?.center}
                                >
                                <div className="space-y-[10px]">
                                    {
                                        fieldsInfoToShow?.map(field => {
                                            let newObjectSrv = formatDataSurvey(coords?.surveyInfos)
                                            return (
                                                <p className="block">
                                                    <span className="!font-[600] text-black">{field?.label}: </span>
                                                    <span>{retirerId(newObjectSrv[formatNameSurvey(field?.value)]?.value)}</span>
                                                </p>
                                            )
                                            
                                        })
                                    }
                                    {
                                        drawerType === 'Polygon' && (
                                            <span 
                                                className="text-primary-blue-500 font-[500] cursor-pointer text-[14px] underline underline-offset-1 block mt-3"
                                                onClick={() => {
                                                    formatDataToShow(coords?.surveyInfos)
                                                }}
                                            >
                                                Voir les informations de la parcelle
                                            </span>
                                        )
                                    }
                                </div>
                                </InfoWindow>
                            ))
                            
                        )
                    }
                    {   showCenter &&
                        coordinatesWithCenter?.map((coords, idx) =>(
                            <Marker
                                key={idx}
                                position={coords?.center}
                                options={centerOptions}
                                onClick={() => handleMarkerClick(coords, 'polygon')}
                            />
                        ))
                    }
                    { delimiter === 'continueWitCoords' && paths?.map((path, index) => (
                        path?.map((coords, idx) =>(
                            <Marker
                                key={index}
                                position={coords}
                                options={markerOptions}
                                onClick={() => handleMarkerClick(coords, 'polygon')}
                            />
                        ))
                        
                    ))}
                    { delimiter === 'startAndEndPoints' && paths?.map((path, index) => (
                        <>
                            <Polyline
                                key={index}
                                path={path}
                                options={mapType === 'roadmap' ? options : optionsSatellite}
                            />
                            <Marker
                                position={path[0]}
                                options={markerStartPoint}
                                onClick={() => handleMarkerClick(path[0], 'polygon')}
                            />
                            <Marker
                                position={path[path?.length - 1]}
                                options={markerEndPoint}
                                onClick={() => handleMarkerClick(path[path?.length - 1], 'polygon')}
                            />
                            
                        </>
                        
                    ))}
                </>
                :
                drawerType === 'userLocation' ? 
                <>
                    {
                        dataMapps?.map((data, i) =>(
                            <Marker 
                                key={i} 
                                position={{ lat: parseFloat(data?.coordinates?.latitude), lng: parseFloat(data?.coordinates?.longitude)}} 
                                onClick={() => handleMarkerClick(data?.coordinates, "marker")}
                            />
                        ))
                    }
                </>
                :
                null
            }
            {selectedMarker && (
                <InfoWindow
                    position={selectedMarker}
                    onCloseClick={() => setSelectedMarker(null)}
                >
                <>
                    <div className="font-bold">{
                        selectedMarkerInfos ? 
                            <>
                                Center <br/><br/>
                            </> 
                        : ''} 
                        Lat : {selectedMarker?.lat}, Lng : {selectedMarker?.lng}</div>
                    {
                        (drawerType === 'Polygon' && selectedMarkerInfos) && (
                            <span 
                                className="text-primary-blue-500 font-[500] cursor-pointer text-[14px] underline underline-offset-1 block mt-3"
                                onClick={() => {
                                    formatDataToShow(selectedMarkerInfos)
                                }}
                            >
                                Voir les informations de la parcelle
                            </span>
                        )
                    }
                </>
                    
                </InfoWindow>
            )}
            </GoogleMap>
        </>
    )
}