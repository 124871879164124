const geolib = require('geolib');

// tranformUnit
function transformSquareUnit(value) {
  switch (value) {
    case "m²":
      return "m2";
    case "km²":
      return "km2";
    case "cm²":
      return "cm2";
    case "ft²":
      return "ft2";
    case "in²":
      return "in2";
    default:
      return value;
  }
}

// Utilisation de la fonction de la bibliothèque pour calculer l'aire
function getAreaOfPolygon(data, unit){
    let unitTransformed = transformSquareUnit(unit)
    const area = geolib.getAreaOfPolygon(data);
    let convert = geolib.convertArea(area, unitTransformed)
    return convert.toFixed(6)
}

// Utilisation de la fonction de la bibliothèque pour calculer le périmètre
function getPathLength(data, unit){
    // console.log('data perimetre', data)
    const perimeter = geolib.getPathLength(data);
    let convert = geolib.convertDistance(perimeter, unit)
    return convert.toFixed(6)
}

function getTotalSurface(polygones, areaUnit, perimeterUnit){
  let unitTransformed = transformSquareUnit(areaUnit)

    const totalSurface = polygones.reduce((accumulator, polygon) => {
        if (polygon && polygon.length > 2) {
          // Vérifie si le polygone est défini et a au moins 3 points pour former un polygone valide
          const area = geolib.getAreaOfPolygon(polygon);
          return accumulator + area;
        } else {
          // Polygone indéfini ou vide, ne pas ajouter à l'accumulateur
          return accumulator;
        }
    }, 0);

    const totalPerimetre = polygones.reduce((accumulator, polygon) => {
        if (polygon && polygon.length > 2) {
          const perimetre = geolib.getPathLength(polygon);
          return accumulator + perimetre;
        } else {
          return accumulator;
        }
    }, 0);

    let convertTotalSurface = geolib.convertArea(totalSurface, unitTransformed)
    let convertTotalPerimetre = geolib.convertDistance(totalPerimetre, perimeterUnit)

    return { aire: convertTotalSurface.toFixed(6) , perimeter: convertTotalPerimetre}
}

// function getTotalSurface(polygones){
//     // const polygones = [
//     //     [
//     //       { latitude: 37.7749, longitude: -122.4194 },
//     //       { latitude: 37.7749, longitude: -122.5294 },
//     //       { latitude: 37.8749, longitude: -122.5294 },
//     //       { latitude: 37.8749, longitude: -122.4194 },
//     //     ],
//     //     // Ajoutez d'autres polygones ici...
//     // ];

//     const totalSurface = polygones.reduce((accumulator, polygon) => {
//         const area = geolib.getAreaOfPolygon(polygon);
//             return accumulator + area;
//     }, 0);

//     const totalPerimetre = polygones.reduce((accumulator, polygon) => {
//         const perimetre = geolib.getPathLength(polygon);
//             return accumulator + perimetre;
//     }, 0);

//         return { aire: totalSurface.toFixed(6) , perimeter: totalPerimetre}
// }

export{
    getAreaOfPolygon,
    getPathLength,
    getTotalSurface
}


